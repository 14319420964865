.zohoIntegrationPage {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    &-heading {
        padding: 1rem;
        background-color: #eee;
        border-bottom: 1px solid #aaa;
    }

    &-container {
        padding: 2rem;
        max-width: 800px;
        margin: 0 auto;
    }

    &-disclaimer {
        background-color: #ffffe0;
        border-bottom: 1px solid #ccc;
        padding: 1rem;
        line-height: 1.8;

        ul {
            padding-inline-start: 30px;
        }
    }

    &-wrapper {
        padding-top: 3rem;

        &-row {
            display: flex;
            align-items: center;
            padding-bottom: 1rem;
            width: 100%;

            &-title {
                min-width: 30%;
            }

            &-name {
                width: 100%;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;
                border-left: 3px solid red;
                padding: 0.5rem 1rem;
                border-radius: 0.25rem;
                background-color: #fff;
                font-size: 16px;
            }

            &-select {
                width: 100%;
                display: flex;
                align-items: stretch;
                justify-content: space-between;

                &-name {
                    width: 100%;
                    border-top: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                    border-left: 3px solid red;
                    padding: 0.5rem 1rem;
                    border-radius: 0.25rem;
                    background-color: #fff;
                    font-size: 16px;
                }

                &-btn {
                    margin-left: 2rem;
                    border: 1px solid #ddd;
                    background-color: #fff;
                    font-size: 16px;
                    padding: 0.5rem 1rem;
                    border-radius: 0.25rem;
                    text-decoration: none;

                    &:hover {
                        background-color: #eee;
                    }
                }
            }
        }

        &-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
        }
    }
}