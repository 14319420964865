.datatable {
    position: relative;
    height: 100%;
    padding: 0 10px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;

    &-copy-icon {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        cursor: pointer;
    }

    &-header {
        display: flex;

        >div {
            flex: 1;
            line-height: 52px;
            color: #334d6e;
            font-weight: 600;
            font-size: 16px;
            padding: 0 16px;
            flex-shrink: 0;
            box-sizing: border-box;
            cursor: pointer;
            font-family: "Poppins";
            flex-basis: 0;

            &.row-checkbox {
                flex: none;
            }
        }

        .row-header {
            display: flex;
            align-items: center;
            position: relative;
            white-space: nowrap;

            &:hover .row-header-sort {
                visibility: visible;
                transition: all 0.3s ease-in-out;

                svg {
                    fill: rgba(0, 0, 0, 0.54);
                }
            }

            .row-header-sort {
                visibility: hidden;
                display: flex;
                height: 18px;
                width: 18px;
                margin-left: 5px;
                transition: all 0.3s ease-in-out;
                transform: rotate(180deg);
                flex-shrink: 0;

                &.desc {
                    visibility: visible;
                    transform: rotate(180deg);
                }

                &.asc {
                    visibility: visible;
                    transform: rotate(0deg);
                }

                svg {
                    fill: rgba(0, 0, 0, 1);
                }
            }

            .row-column-filter {
                position: absolute;
                top: 50px;
                right: 10px;
                max-width: 250px;
                box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
                    0px 1px 3px 0px rgb(0 0 0 / 12%);
                background: #fff;
                line-height: 1.4;
                font-size: 14px;
                z-index: 3;

                >div {
                    margin: 5px 0;
                    padding: 5px 15px;

                    &:hover,
                    &.selected {
                        background-color: #ccc;
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .column-filter {
                margin-left: 10px;
                padding: 10px;
                border-radius: 20px;

                &:hover {
                    background-color: #f1f1f1;
                }

                div {
                    height: 4px;
                    width: 4px;
                    background-color: #6b6b6b;
                    border-radius: 5px;
                    position: relative;

                    &:before,
                    &:after {
                        content: "";
                        height: 4px;
                        width: 4px;
                        background-color: #6b6b6b;
                        border-radius: 5px;
                        position: absolute;
                        left: 0;
                    }

                    &:after {
                        top: -6px;
                    }

                    &:before {
                        top: 6px;
                    }
                }
            }
        }
    }

    .table-loading-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .table-loader {
            border: 4px solid #f3f3f3;
            -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
            border-top: 4px solid #555;
            border-radius: 50%;
            width: 32px;
            height: 32px;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 400px;
        position: relative;

        &:focus-visible {
            outline: none;
        }

        .datatable-row {
            display: flex;
            border-bottom: 1px solid rgba(224, 224, 224, 1);
            position: relative;

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            &:last-child {
                border-bottom: none;
            }

            >div {
                flex: 1;
                // line-height: 72px;
                font-size: 16px;
                padding: 0 16px;
                flex-shrink: 0;
                display: flex;
                box-sizing: border-box;
                flex-basis: 0;
                white-space: nowrap;
                height: 52px;

                &.row-checkbox {
                    flex: none;
                }

                >* {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .datatable-footer {
            display: flex;
            justify-content: flex-end;
            margin-top: auto;

            .datatable-footer-wrapper {
                display: flex;
                align-items: center;

                .datatable-footer-pageinfo {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 56px;
                }

                .datatable-footer-navigation {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;

                    .datatable-footer-prev,
                    .datatable-footer-next {
                        padding: 12px;
                        height: 24px;
                        width: 24px;
                        position: relative;
                        cursor: pointer;

                        &.disabled:before {
                            border-color: #ccc;
                        }

                        &:before {
                            content: "";
                            display: block;
                            border-top: 2px solid #000;
                            border-left: 2px solid #000;
                            height: 8px;
                            width: 8px;
                            transform: rotate(-45deg);
                            position: absolute;
                            top: 18px;
                        }
                    }

                    .datatable-footer-next {
                        &:before {
                            transform: rotate(135deg);
                        }
                    }
                }
            }
        }
    }

    .row-checkbox {
        color: #6b6b6b;
        font-size: 13px;
        font-weight: 500;
        position: relative;
        margin-top: 0;
        flex: none;
        width: 55px;

        &:last-child {
            margin-bottom: 0;
        }

        >label {
            display: block;
            line-height: 24px;
        }

        input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 20px;
            left: 18px;
            height: 14px;
            width: 14px;
            border: 2px solid #b4b4b4;
            cursor: pointer;
            border-radius: 0;

            &-content {
                position: absolute;
                top: 110%;
                left: 0;
                z-index: 1;
                background-color: #fff;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                min-width: 120px;

                &-text {
                    padding: 5px 10px;
                    cursor: pointer;
                    line-height: 1.4;

                    &:hover {
                        background-color: #eee;
                    }
                }
            }

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 4px;
                top: 0px;
                width: 4px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .row-checkbox .checkmark:hover input~.checkmark {
        background-color: #ccc;
    }

    .row-checkbox input:checked~.checkmark {
        background-color: #479bd2;
        border-color: #479bd2;
    }

    .row-checkbox input:checked~.checkmark:after {
        display: block;
    }

    .filter-checkbox {
        color: #6b6b6b;
        font-size: 13px;
        font-weight: 500;
        position: relative;
        margin-top: 1px;
        flex: none;
        width: 18px;
        height: 18px;
        margin-right: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        >label {
            display: block;
            line-height: 24px;
            width: 18px;
            height: 18px;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 14px;
            width: 14px;
            border: 2px solid #b4b4b4;
            cursor: pointer;
            border-radius: 0;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 4px;
                top: 0px;
                width: 4px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .filter-checkbox .checkmark:hover input~.checkmark {
        background-color: #ccc;
    }

    .filter-checkbox input:checked~.checkmark {
        background-color: #479bd2;
        border-color: #479bd2;
    }

    .filter-checkbox input:checked~.checkmark:after {
        display: block;
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}