.select {
	width: 200px;
	position: relative;
	box-sizing: border-box;
	background-color: #F2F2F2;

	.wrapper {
		margin: 0 50px 0 1rem;
		font-size: 14px;
		position: relative;
		cursor: pointer;
		line-height: 30px;
		border-bottom: 1px solid rgb(196, 196, 196);

		&.value-selected {
			color: #000;
		}

		&.label-selected {
			color: #7a7a7a;
		}

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 8px solid #C4C4C4;
			position: absolute;
			right: -42px;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&.empty {
			color: #a6a6a6;
		}
	}

	.dropdown {
		box-shadow: 0px 4px 6px 0px #00000040;
		border-top: none;
		border-radius: 0 0 5px 5px;
		background-color: #fff;
		padding: 10px 0;
		font-size: 14px;
		position: absolute;
		left: 0;
		right: 0;
		z-index: 2;

		.dropdown-item {
			padding: 5px 25px;
			color: #707070;
			cursor: pointer;

			&:hover {
				background-color: #e9e9e9;
			}
		}
	}

	.select-floating-label {
		position: absolute;
		top: -9px;
		left: 13px;
		font-size: 13px;
		color: #BBBBBB;
		background: linear-gradient(180deg, #fff 50%, #f2f2f2 50%);
	}
}

@media screen and (max-width: 768px) {
	.select {
		min-width: 200px;
		width: 100%;

		.wrapper {
			margin: 0;
			width: unset;

			&::after {
				right: 10px;
			}
		}
	}
}