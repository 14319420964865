.container-div.manage-invitations {
	height: 100vh;
	overflow-x: scroll;
}

.input-div-applicant {
	margin: 20px 0 40px;
}

.input-div-applicant input {
	margin: 10px 0;
	padding: 10px 20px;
	width: 350px;
	border-radius: 25px;
	border: 1px solid #aaa;
}

.input-div-applicant p {
	color: red;
	font-size: 12px;
	margin-left: 15px;
}

.button-div {
	display: flex;
	flex-direction: row-reverse;
}

.choose-file-div {
	margin: 20px 0 20px 10px;
}

.MuiDataGrid-columnHeaderWrapper {
	background-color: #fff;
}

.MuiDataGrid-footerContainer {
	display: none;
}

.container-div {
	margin: 0 auto;
}

.search-box {
	width: 45vw;
}

.search-box input {
	width: 100%;
	outline: none;
	border: none;
	padding: 10px 20px;
	border-radius: 17px;
}

.search-box input::placeholder {
	padding-left: 20px;
	font-weight: 500;
	font-size: 15px;
	color: rgb(150, 150, 150);
}

.search-box input:placeholder-shown {
	background-image: url("../assets/Icons/search.png");
	background-position: 20px 9.5px;
	background-repeat: no-repeat;
}

#btn1 {
	margin-right: 20px;
}

.cards-container {
	width: 100%;
	overflow-x: scroll;
	margin-bottom: 10px;
	padding: 10px 0 0 0;
}

.cards {
	width: max-content;
	margin: auto;
	border-radius: 25px;
	margin-bottom: 25px;
	display: flex;
	align-items: stretch;
}

.cards>div {
	width: 300px;
}

.cards>div.add-card-container {
	width: 180px;
}

/* .cards > div {
   flex: 1 25%;
}

@media only screen and (max-width: 1100px) {
   .cards > div {
      flex: 1 50%;
   }
 } */

.reinvite-btn {
	border: none;
	background-color: #479bd2;
	border-radius: 17px;
	color: white;
	padding: 5px 10px;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer {
	text-align: center;
}

.invite-later-btn {
	background-color: #f0f0f0;
	border-radius: 25px;
	margin: 0 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	position: relative;
}

.invite-later-btn .invite-later-text {
	padding: 4px 8px 4px 16px;
	font-weight: bold;
	font-size: 16px;
	border-right: 1px solid #B4B4B4;
}

.invite-later-btn .arrowdropdownicon {
	display: inline-block;
	width: 20px;
	height: 20px;
	padding-right: 8px;
}

.scheduleInviteModal {
	width: 100%;
	max-width: 500px;
	border-radius: 10px;
	padding: 1.2rem 40px;
	box-sizing: border-box;
	border-radius: 10px !important;

	&.deadline {
		max-width: 550px;
	}
}

.schedule-invite-box {
	position: absolute;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 10px;
	padding: 6px 0.5rem;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	bottom: 115%;
	left: 0;
	width: max-content;
}

.schedule-invite-box>img {
	width: 16px;
	height: auto;
}

.schedule-invite-box p {
	color: #303030;
	font-weight: 600;
	font-size: 15px;
	margin-left: 0.5rem;
}

.schedule-invite-btns-box {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 1.5rem;
}

.schedule-invite-btns-box .cancel-button {
	background-color: #f0f0f0;
	padding: 4px 16px;
	font-weight: 700;
	margin-right: 1rem;
	border-radius: 1rem;
	cursor: pointer;
}

.schedule-invite-btns-box .schedule-btn {
	background-color: #479bd2;
	padding: 4px 16px;
	font-weight: 700;
	color: #fff;
	border-radius: 1rem;
	cursor: pointer;
}

.schedule-subheading {
	color: #676767;
	margin: 0.5rem 0 0;
	width: fit-content;
}

.date-time-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
}

.action-btns-box.manage-invitations {
	font-size: 15px;
	display: flex;
	align-items: center;
}

.invitationModal {
	border-radius: 10px;
	padding: 1.2rem 40px;
	min-width: 500px;
	width: max-content;

	.reminder-template-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.remind-after-clock-box {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&__wrapper {
		position: relative;
		margin-top: 20px;

		&:focus-visible {
			outline: none;
		}
	}

	&__wrapper1 {
		margin-top: 20px;
		min-width: 400px;
	}
}

.manageInvitations-remind-btn {
	position: relative;
	background-color: #479bd2;
	font-weight: bold;
	font-family: inherit;
	letter-spacing: 0.01em;
	border-radius: 25px;
	padding: 5px 21px;
}

.manageInvitations-remind-btn .notificationBell {
	background-color: #909090;
	position: absolute;
	top: -10px;
	right: -12px;
	border-radius: 50%;
	display: flex;
	align-items: flex-start;
	padding: 0.2rem 0.1rem;
}

.manageInvitations-remind-btn .notificationBellIcon {
	width: 18px;
	height: 18px;
}

.manageInvitations-remind-btn .superscript {
	font-size: 10px;
}

@media screen and (max-width: 768px) {
	.container-div.manage-invitations {
		display: flex;
		padding: 0;
		overflow-x: unset;
		flex-direction: column;
	}

	.inner-div-details.manage-invitations {
		align-items: center;
		margin-top: 0.5rem;
	}

	.manage-icon {
		width: 30px;
		height: auto;
	}

	.manage-icon img {
		width: 100%;
		height: auto;
	}

	.action-btns-box.manage-invitations {
		display: none;
	}

	.choose-file-div {
		margin-left: 0;
	}

	.invite-individual-content {
		padding-left: 0;
	}

	.invite-individual-content .input-div-applicant #manageInvitation-selector-3,
	.invite-individual-content .input-div-applicant #manageInvitation-selector-4 {
		width: 100%;
		box-sizing: border-box;
	}

	.invite-individual-content .button-div {
		padding-right: 0;
	}

	.invitationModal {
		min-width: unset;
		width: 100%;
		box-sizing: border-box;
		padding: 1.2rem;
		max-width: fit-content;

		&__wrapper {
			margin-top: 0;
		}

		&__wrapper1 {
			min-width: unset;
		}

		.modal-heading-workflow {
			margin-top: 0;
		}

		.reminder-template-box {
			flex-wrap: wrap;
		}

		.remind-after-clock-box {
			width: 100%;
		}
	}

	.new-applicant-box__content {
		padding: 0 20px;
	}

	.invite-later-btn {
		margin: 0;
	}

	.invite-later-btn .invite-later-text {
		font-size: 14px;
	}

	.scheduleInviteModal {
		width: fit-content;
		padding: 1.2rem;
		margin: 0;
	}

	.schedule-invite-btns-box .schedule-btn,
	.schedule-invite-btns-box .cancel-button {
		font-size: 14px;
	}
}

.manage-invitations .tooltip .tooltiptext {
	z-index: 3;
}