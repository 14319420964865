.switch {
    position: relative;
    display: inline-block;
    min-width: 52px;
    height: 21px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    input:checked+.slider {
        background-color: #81DB57;
    }
    
    input:focus+.slider {
        box-shadow: 0 0 1px #81DB57;
    }
    
    input:checked+.slider:before {
        -webkit-transform: translateX(30px);
        -ms-transform: translateX(30px);
        transform: translateX(30px);
    }
    
    /* Rounded sliders */
    .slider.round {
        border-radius: 21px;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }
}

@media screen and (max-width: 768px) {
    .switch {
        width: 50px;
        height: 24px;

        .slider::before {
            height: 16px;
            width: 16px;
        }

        .slider-round {
            border-radius: 24px;
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(28px);
            -ms-transform: translateX(28px);
            transform: translateX(28px);
        }
    }
}
