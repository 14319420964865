.custom-checkbox {
   position: relative;
}

.custom-checkbox label {
   padding-left: 30px;
   display: block;
   line-height: 24px;
}

.custom-checkbox label input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
}

.custom-checkbox label .checkmark {
   position: absolute;
   top: 2px;
   left: 0;
   height: 16px;
   width: 16px;
   border: 2px solid rgb(184, 184, 184);
   cursor: pointer;
   border-radius: 3px;
}

.custom-checkbox label input:checked~.checkmark {
   background-color: #479bd2;
   border-color: #479bd2;
}

.custom-checkbox label input:checked~.checkmark:after {
   display: block;
}

.custom-checkbox label .checkmark:after {
   content: "";
   position: absolute;
   display: none;
   left: 5px;
   top: 0;
   width: 4px;
   height: 10px;
   border: solid white;
   border-width: 0 2px 2px 0;
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
}

.custom-checkbox label .green-input {
   &:checked~.checkmark {
      background-color: #043840;
      border-color: #043840;
   }
}

.custom-checkbox label .green-checkmark {
   border-radius: 50%;
}
