.integration {
    &-page {
        flex: 1;
        padding: 20px;
        box-sizing: border-box;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    &-wrapper {
        background-color: #fff;
        padding: 20px;
        border: 1px solid #D3D3D3;
        border-radius: 0.5rem;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-left {
                display: flex;
                align-items: center;

                &-img {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    margin-right: 1rem;
                    border-radius: 4px;
                }

                &-name {
                    font-size: 1.1rem;
                    font-weight: bold;
                }
            }
        }

        &-desc {
            font-size: 1rem;
            margin-top: 1rem;
        }
    }
}