.avatar {
    &-container {
        box-sizing: border-box;
    }

    &-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    &-text {
        border-radius: 50%;
        background: #C4C4C4;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        padding: 0.5rem;
        min-width: 1.25em;
    }
}