:root {
	--primary-light: #1344bc;
	--primary: #0a2463;
	--primary-dark: #06173f;

	--secondary-light: #96ce96;
	--secondary: #62b662;
	--secondary-dark: #499c49;

	--white: #ffffff;
	--greyLight-1: #e4ebf5;
	--greyLight-2: #6a6666;
	--greyLight-3: #bec8e4;
	--greyDark: #9baacf;
	--black: #000;
	--shadow: 0rem 0rem 0rem 0.2rem var(--greyLight-2), 0rem 0rem 0.6rem 0.2rem var(--black);
	--shadow-secondary: 0rem 0rem 0.3rem 0.5rem #0f3f79, -0.2rem -0.4rem 1.6rem #93c4ff;
}

.arrow {
	box-sizing: border-box;
	height: 1vw;
	width: 1vw;
	border-style: solid;
	border-color: white;
	border-width: 0px 1px 1px 0px;
	transform: rotate(45deg);
	transition: border-width 150ms ease-in-out;
	margin-left: 5vh;
	padding: 2px 0;
}

.arrow:hover {
	border-bottom-width: 4px;
	border-right-width: 4px;
}

.btn {
	width: 15rem;
	height: 3rem;
	border-radius: 1.5rem;
	box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white);
	justify-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.3s ease;
}

.btn__primary {
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--primary);
	box-shadow: inset 0.2rem 0.2rem 1rem var(--primary-light), inset -0.2rem -0.2rem 1rem var(--primary-dark),
		var(--shadow);
	color: var(--greyLight-1);
}

.btn__primary1 {
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--greyLight-2);
	box-shadow: inset 0.2rem 0.2rem 1rem var(--greyLight-3), inset -0.2rem -0.2rem 1rem var(--primary-dark),
		var(--shadow);
	color: var(--primary);
}

.btn__primary2 {
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--greyLight-1);
	box-shadow: inset 0.2rem 0.2rem 1rem var(--primary-light), inset -0.2rem -0.2rem 1rem var(--greyDark), var(--shadow);
	color: var(--primary);
}

.btn__secondary {
	width: 15rem;
	height: 4vh;
	margin: auto;
	border-radius: 2vh;
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--secondary);
	box-shadow: inset 0.2rem 0.2rem 1rem var(--secondary-light), inset -0.2rem -0.2rem 1rem var(--secondary-dark),
		var(--shadow-secondary);
	color: white;
	padding: 0.4vh 1.4vw;
	text-transform: capitalize;
	font-size: 1.1vw;
}

.btn:hover {
	color: var(--white);
}

.btn:active {
	color: var(--white);
}

.btnColor1 {
	color: #2f55af;
}

.btnColor2 {
	color: #0c95ba;
}

.btnColor3 {
	color: #10054d;
}

.btnColor4 {
	color: #3ab9d6;
}

.btnColor5 {
	color: #000000;
}

.btnColor6 {
	color: #131b23;
}

.btnColor7 {
	color: #dbdbdb;
}

.btnColor8 {
	color: #e9eef3;
}

.btnColor9 {
	color: #0e6495;
}

.btnColor10 {
	color: #0e6495;
}

.btnColor11 {
	color: #0f3f79;
}

.btnColor12 {
	color: #25054d;
}

.btnColor13 {
	color: #42b6d6;
}

.btnColor14 {
	color: #dc3545;
}

.btnColor15 {
	color: #666666;
}

.btnColor16 {
	color: #62b662;
}

.btnColor17 {
	color: #c4c4c4;
}

.btnColor18 {
	color: #92e7ff;
}

.btnColor19 {
	color: #99fbff;
}

.btnColor20 {
	color: #0d95bb;
}

.btnColor21 {
	color: #cccccc;
}

.btnColor22 {
	color: #d9d9d9;
}

.btnColor23 {
	color: #ee4057;
}

.btnColor24 {
	color: #359faf;
}

.btnColor25 {
	color: #d443a2;
}

.btnColor26 {
	color: #7121ff;
}
