.pre-screening-dashboard {
	flex: 1;
	padding: 20px 20px 0 20px;
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.pre-screeening-wrapper {
		padding: 20px;
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	.add-prescreen-btn {
		display: flex;
	}
	
	.add-prescreen-btn {
		display: flex;
	}
	
	.add-prescreen-btn {
		display: flex;
	}
}

.option-item {
	margin-bottom: 10px;
	color: #6b6b6b;
	font-size: 13px;
	font-weight: 500;
	position: relative;
	margin-top: 0;
	flex-basis: 0;

	&:last-child {
		margin-bottom: 0;
	}
}

.option-item>label {
	padding-left: 32px;
	display: block;
	line-height: 24px;
}

.option-item input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.option-item .checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 16px;
	width: 16px;
	border: 2px solid #b4b4b4;
	cursor: pointer;
	border-radius: 0;
}

.option-item .checkmark:hover input~.checkmark {
	background-color: #ccc;
}

.option-item input:checked~.checkmark {
	background-color: #479bd2;
	border-color: #479bd2;
}

.option-item .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.option-item input:checked~.checkmark:after {
	display: block;
}

.option-item .checkmark:after {
	left: 5px;
	top: 2px;
	width: 4px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.prescreening-btns-box {
	text-align: center;
}

.add-question-btn {
	width: 32px;
	height: 32px;
	background-color: #479bd2;
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	position: relative;
	cursor: pointer;

	&:after,
	&:before {
		content: '';
		height: 3px;
		width: 20px;
		background-color: #fff;
		display: block;
		border-radius: 3px;
		position: absolute;
	}

	&:before {
		transform: rotate(90deg);
	}
}

.delete-question-btn {
	margin-left: 20px;
	margin-top: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.addPrescreeeningBotModal {
	.add-prescreening-bot-wrapper {
		padding: 20px 40px;

		.add-questions-box {
			display: flex;
			margin-right: 100px;

			.add-questions {
				margin-right: 100px;
			}
		}
	}
}

.welcome-page-modal {
	width: max-content;
	padding: 2rem 3rem;

	&-title {
		font-weight: 700;
		font-size: 24px;
		color: #4b4b4b;
	}

	&-flex-box {
		display: flex;
		align-items: center;

		.participant-heading {
			font-weight: 700;
			font-size: 28px;
			color: #4b4b4b;
		}

		.participant-input {
			font-weight: 700;
			font-size: 32px;
			width: 100%;
		}

		.company-heading {
			font-weight: 700;
			font-size: 20px;
			color: #4b4b4b;
		}

		.links-heading {
			font-weight: 700;
			font-size: 18px;
			color: #479BD2;
			cursor: pointer;
		}

		.end-prescreen-heading {
			font-weight: 700;
			font-size: 17px;
			color: #6B6B6B;
			margin-right: 1rem;
		}
	}

	&-logo-box {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
	}

	&-participant-box {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
	}

	&-company-box {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	&-links-box {
		display: flex;
		align-items: center;
		margin: 1rem 0;
	}

	&-endprescreen-box {
		display: flex;
		align-items: center;
	}

	&-textarea {
		width: 100%;
		border: none;
		border-radius: 1rem;
		background-color: #F1F1F1;
		padding: 0.5rem 1rem;
		box-sizing: border-box;
		height: 200px;
		overflow-y: auto;
	}

	&-info-icon {
		border-radius: 50%;
		color: #B3B3B3;
		border: 2px solid #B3B3B3;
		padding: 0 0.5rem;
		margin-left: 0.5rem;
	}

	&-create-box {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;
	}

	&-img-box {
		position: relative;
		height: 500px;
		width: 50%;
		margin-left: 3rem;
		display: flex;
		align-items: center;
		border-radius: 0.5rem;
		flex-shrink: 0;

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
			opacity: 0.75;
		}

		.add-btn {
			border: none;
			position: absolute;
			background-color: white;
			border-radius: 0.5rem;
			color: black;
			padding: 0.5rem 1rem;
			top: 80%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-weight: 700;
			font-size: 18px;
			box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
		}
	}
}

.add-image-modal {
	padding: 2rem 3rem;

	.heading {
		font-weight: 700;
		font-size: 24px;
		margin-bottom: 1rem;
	}

	.btn-box {
		display: flex;
		justify-content: center;
	}

	.fileInput {
		background-color: #F1F1F1;
		padding: 0.5rem 1rem;
		border-radius: 1rem;
		box-sizing: border-box;
		width: 100%;
		border: none;
	}

	.divider {
		font-weight: bold;
		text-align: center;
		margin: 0.5rem 0;
	}
}

.add-logo-modal {
	padding: 2rem 3rem;

	.heading {
		font-weight: 700;
		font-size: 24px;
		margin-bottom: 1rem;
	}

	.btn-box {
		display: flex;
		justify-content: center;
	}

	.fileInput {
		background-color: #F1F1F1;
		padding: 0.5rem 1rem;
		border-radius: 1rem;
		box-sizing: border-box;
		width: 100%;
		border: none;
	}
}

.add-links-modal {
	padding: 2rem 3rem;

	.heading {
		font-weight: 700;
		font-size: 24px;
		margin-bottom: 1rem;
	}

	.btn-box {
		display: flex;
		justify-content: center;
	}

	.links-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}

		p {
			font-weight: 500;
			font-size: 15px;
			color: #5E5E5E;
			margin-right: 1.5rem;
		}

		input {
			border: none;
			border-radius: 2rem;
			background-color: #F2F2F2;
			padding: 0.5rem 1rem;
			width: 400px;
		}
	}

	.addPrescreeeningBotModal {
		box-sizing: border-box;
		width: 100%;
		max-width: min-content;

		.add-prescreening-bot-wrapper {
			padding: 0;

			.add-questions-box {
				flex-direction: column;
				margin-right: 0;

				.add-questions {
					margin-right: 0;
				}
			}
		}
	}

	.add-question-btn {
		margin-left: 0;
		margin-top: 10px;
		width: 24px;
		height: 24px;

		&::before {
			width: 18px;
		}

		&::after {
			width: 18px;
		}
	}

	.delete-question-btn {
		margin-top: 10px;
		margin-left: 20px;
	}

	.add-prescreening-bot-wrapper {
		padding: 20px;
		background-color: white;
	}
}

@media screen and (max-width: 768px) {
	.pre-screening-dashboard {
		padding: 0;

		.pre-screeening-wrapper {
			padding-bottom: 52px;
			overflow-y: auto;
			background-color: white;
		}

		.add-prescreen-btn {
			position: fixed;
			box-sizing: border-box;
			bottom: 52px;
			padding: 0.5rem 20px;
			width: 100%;
			left: 0px;
			z-index: 2;
			background-color: white;
		}

		.prescreening-box__row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0.5rem 0;
			padding: 0.5rem;
		}

		.prescreening-box__row-active {
			border-radius: 8px;
			background-color: #EFF7FF;
		}
		.prescreening-box__row-left {
			width: 50%;
			word-break: break-all;
			display: flex;
			align-items: center;
		}

		.prescreening-box__row-left img {
			margin-right: 0.5rem;
		}
		.prescreening-box__row-left-text-name {
			font-weight: 600;
			font-size: 15px;
			color: #404040;
		}
		.prescreening-box__row-left-text-role {
			font-weight: 600;
			font-size: 12px;
			color: #858585;
		}
		.prescreening-box__row-right {
			display: flex;
			align-items: center;
		}
	}

	.addPrescreeeningBotModal {
		box-sizing: border-box;
		width: 100%;
		max-width: min-content;

		.add-prescreening-bot-wrapper {
			padding: 0;

			.add-questions-box {
				flex-direction: column;
				margin-right: 0;

				.add-questions {
					margin-right: 0;
				}
			}
		}
	}

	.add-prescreening-bot-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-bottom: 10px !important;
		overflow-y: auto;
	}

	.prescreening-btns-box {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.add-question-btn {
		margin-left: 0;
		margin-top: 10px;
		width: 24px;
		height: 24px;

		&::before {
			width: 18px;
		}

		&::after {
			width: 18px;
		}
	}

	.delete-question-btn {
		margin-top: 10px;
		margin-left: 20px;
	}

	.add-prescreening-bot-wrapper {
		padding: 20px;
		background-color: white;
	}
}
