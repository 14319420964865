.icon-button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: 0;
    border-radius: 50%;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    -webkit-transition: all .15s ease-in;
    transition: all .15s ease-in;
    background-color: transparent;
    padding: 0.4rem 0.5rem;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}

@-webkit-keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: .5;
    }

    100% {
        width: 40px;
        height: 40px;
        opacity: 0;
    }
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: .5;
    }

    100% {
        width: 40px;
        height: 40px;
        opacity: 0;
    }
}

.ripple-effect:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: currentColor;
    visibility: hidden;
    z-index: 2;
}

.ripple-effect:not(:active):before {
    -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
    animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
    -webkit-transition: visibility .4s step-end;
    transition: visibility .4s step-end;
}

.ripple-effect:active:before {
    visibility: visible;
}