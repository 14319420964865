.change-password {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	position: relative;
	flex: 1;

	.change-password-actions {
		position: absolute;
		bottom: 0;
		right: 0;

		>div {
			color: #fff;
			width: 200px;
			padding: 5px 0;
			text-align: center;
			border-radius: 20px;
			background-color: #479BD2;
			font-weight: 700;
			cursor: pointer;

			&:last-child {
				margin-right: 25px;
			}
		}
	}

	.input-group {
		display: flex;
		align-items: center;
		margin-bottom: 60px;
		position: relative;
		width: 250px;
		border-bottom: 1px solid #818181;

		input {
			width: 250px;
			outline: none;
			border: none;
			background: transparent;
			font-size: 14px;
			font-family: inherit;
			color: #a4a4a4;
			padding: 5px 0;
		}
	}
}

@media screen and (max-width: 768px) {
	.change-password {
		.input-group {
			width: 100%;
		}

		.change-password-actions {
			display: flex;
			justify-content: center;
			position: unset;
			bottom: unset;
			right: unset;
			margin-top: auto;

			>div:last-child {
				margin-right: 0;
			}
		}
	}
}