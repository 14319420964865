#addNewDriveModal {
	padding: 20px 40px;
	width: 720px;

	.title {
		font-size: 24px;
		color: #363434;
		font-weight: 700;
		margin-left: 0;
	}

	.subtitle {
		margin-top: 5px;
		font-size: 18px;
		color: #676767;
		font-weight: 700;
	}

	.default-list {
		margin-top: 80px;
		display: flex;
		flex-direction: row;
		padding: 0 5px 10px;
		overflow-x: auto;

		.list-item {
			display: flex;
			flex-direction: column;
			flex: 0 0 calc(25% - 24px);
			text-align: center;
			margin-right: 32px;
			color: #bdbdbd;
			font-size: 14px;
			font-weight: 700;

			.list-item-box {
				text-align: center;
				box-shadow: 0px 4px 4px 0px #00000040;
				border-radius: 12px;
				padding: 30px 15px;
				font-size: 12px;
				color: #787878;
				font-weight: 700;
				white-space: normal;
				background-color: #f9f9f9;
				margin-bottom: 12px;
				cursor: pointer;

				img {
					width: 40px;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.divider {
		text-align: center;
		margin: 25px 0 40px;
		font-size: 20px;
		color: #676767;
		position: relative;

		&:before,
		&:after {
			content: "";
			display: block;
			height: 1px;
			width: calc(50% - 25px);
			background-color: #c4c4c4;
			position: absolute;
			top: 50%;
		}

		&:before {
			right: 0;
		}
	}

	.action {
		cursor: pointer;
		font-size: 20px;
		color: #fff;
		padding: 12px 30px;
		border-radius: 15px;
		background-color: #479bd2;
		font-family: inherit;
		line-height: 1.2;
	}

	input[type="text"],
	textarea {
		font-family: inherit;
		font-size: 16px;
		padding: 16px 50px;
		background-color: #f2f2f2;
		border-radius: 16px;
		border: none;
		outline: none;
		color: #000;
		margin-top: 32px;
		width: 75%;

		&::placeholder {
			color: #90a0b7;
		}
	}

	.default-form {
		&:focus-visible {
			outline: none;
		}

		.action {
			margin-top: 80px;
		}
	}

	.back-btn {
		margin-right: 32px;
		cursor: pointer;
		padding: 10px 0;

		>div {
			position: relative;
			width: 40px;
			height: 2px;
			background-color: #000;

			&:before,
			&:after {
				content: "";
				display: block;
				height: 2px;
				width: 12px;
				left: -2px;
				position: absolute;
				background-color: #000;
			}

			&:before {
				transform: rotate(45deg);
				top: 4px;
			}

			&:after {
				transform: rotate(-45deg);
				top: -4px;
			}
		}
	}

	.add-role-select {
		flex: 1;
		height: 35px;
		margin: 10px;
		background: rgb(242, 242, 242);
		border-radius: 30px;
		max-width: 420px;
	}

	.add-wf-select {
		flex: 1;
		height: auto;
		margin: 10px;
		background: rgb(242, 242, 242);
		border-radius: 30px;
		max-width: 420px;
	}

	.key-task {
		margin-top: 15px;
		font-size: 12px;
		padding: 6px 12px;
		background-color: #ededed;
		color: #5a5a5a;
		border-radius: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&-activekey {
			background-color: #cfecff;
		}
	}

	.add-video-question {
		.add-video-question-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 20px;
		}

		.add-video-question-form {
			margin-top: 20px;
			padding: 0 20px;

			.add-video-question-item {
				margin-top: 35px;

				.add-video-question-item-title {
					font-size: 16px;
					color: #363740;
					font-weight: 600;
				}

				textarea {
					outline: none;
					width: 100%;
					font-family: inherit;
					font-size: 14px;
					padding: 10px 20px;
					background-color: #f5f5f5;
					border-radius: 14px;
					border: none;
					box-sizing: border-box;
				}
			}
		}
	}

	.create-custom-assessment {
		input[type=text] {
			box-sizing: border-box;
			width: 100%;
			background-color: transparent;
			border-bottom: 1px solid #797979;
			border-radius: 0;
			padding: 16px 0;
		}
	}

	.select {
		background-color: #fff;

		.wrapper {
			color: #000;
		}

		.dropdown {
			.dropdown-item {
				padding: 5px 25px;
				color: #000;
				cursor: pointer;

				&:hover {
					background-color: #cfecff;
				}
			}
		}
	}

	.selectRoleContainer {
		display: flex;
		align-items: center;
		margin-top: 20px;
		justify-content: space-between;
		position: relative;
		background: #fff;
		padding: 5px 0;
	}

	.criticalWFContainer {
		display: flex;
		align-items: center;
		margin-top: 20px;
		position: relative;
		background: #fff;
		padding: 5px 0;
	}

	.customAssHeading {
		width: 30%;
	}

	.customAssText {
		color: #B6B6B6;
		font-size: 14px;
		width: 44%;
		margin-right: 1rem;
	}

	.infoCustomIcon {
		margin-left: 40;
		cursor: pointer;
	}

	.saveAssBtn {
		display: flex;
		justify-content: flex-end;
	}

	.customFieldContainer {
		display: flex;
	}

	.customFieldInputBox {
		flex: 4;
		margin-right: 25px;
	}

	.customVideoContainer,
	.customJobRoleContainer {
		display: flex;
		margin-top: 20px;
		align-items: center;
		position: relative;
		background: #fff;
		padding: 5px 0;
	}

	.customAssessmentDrive {
		&__wrapper {
			&:focus-visible {
				outline: none;
			}
		}
	}

	.variabilityTitle {
		width: 30%;
	}
	
	.variabilitySubtitle {
		color: #B6B6B6;
		font-size: 14px;
		width: 44%;
		margin-right: 1rem;
	}
	
	.skillsRangeContainer {
		background: #f8f8f8;
		padding: 70px 50px 40px;
		position: relative;
	}
}

@media screen and (max-width: 768px) {
	#addNewDriveModal {
		padding: 0;
		width: 100%;

		input[type=text],
		textarea {
			box-sizing: border-box;
			width: 100%;
		}

		.default-list {
			flex-direction: column;
			margin-top: 1rem;
			height: 200px;
			overflow-y: auto;

			.list-item {
				margin-right: 0;
				margin-bottom: 12px;

				&:last-child {
					margin-bottom: 0;
				}

				>div:nth-child(2) {
					display: none;
				}

				.list-item-box {
					padding: 0.5rem 1rem;
					text-align: left;
					margin-bottom: 0;

					>div {
						font-size: 14px;
					}

					img {
						display: none;
					}
				}
			}
		}

		.action {
			background-color: #197BBD;
			border-radius: 8px;
		}

		.selectRoleContainer {
			margin-top: 20px;
			flex-direction: column;
		}

		.criticalWFContainer {
			flex-direction: column;
			margin-bottom: 20px;
		}

		.add-wf-select {
			width: 100%;
		}

		.customAssHeading,
		.customAssText {
			margin-right: 20px;
			width: 40%;
		}

		.infoCustomIcon {
			margin-left: 0;
			margin-top: 10px;
		}

		.saveAssBtn {
			justify-content: center;
			margin-top: 10px;
		}

		.customFieldContainer {
			flex-direction: column;
		}

		.customFieldInputBox {
			margin-right: 0;
			margin-bottom: 16px;
		}

		.customVideoContainer {
			flex-wrap: wrap;
		}

		.customJobRoleContainer {
			justify-content: center;
			flex-wrap: wrap;
			margin-top: 0;
		}

		.skillsRangeContainer {
			padding: 0.5rem;
			margin-top: 0.5rem;
		}

		.variabilityTitle,
		.variabilitySubtitle,
		.variabilityButton {
			width: unset;
		}

		.variabilitySubtitle {
			margin: 0 0.5rem;
		}
	}
}