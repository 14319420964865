#message[contentEditable="true"]:empty:not(:focus):before {
	content: attr(data-placeholder);
}

.focus-border-none:focus {
	border: none;
	outline: none;
}

div#message::-webkit-scrollbar-thumb {
	background: #8cc0e3 !important;
}

[data-rmiz-btn-open] {
	cursor: -webkit-zoom-in !important;
}

[data-rmiz-btn-close] {
	cursor: -webkit-zoom-out !important;
}

.text-transform-none {
	text-transform: none !important;
}

.justify-content-end {
	justify-content: flex-end !important;
	-webkit-justify-content: flex-end !important;
}

.bg-color-blue {
	background-color: rgb(71, 155, 210) !important;
}

.remove-active-border:active,
.remove-active-border:focus {
	border: none !important;
	outline: none !important;
}

.container {
	display: flex;
	/* margin-top: 10px; */
}

body {
	font-family: "Source Sans Pro", sans-serif;
}

.singinBg {
	background-image: url("../assets/signinbg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
}

.dataGridScroll {
	border: none;
	overflow-y: auto;
}

.dataGridScroll::-webkit-scrollbar-thumb {
	background-color: #479bd2;
	width: 6px;
	border-radius: 20px;
}

.dataGridScroll::-webkit-scrollbar-track {
	background-color: #eee;
	margin: 10px 0;
	border-radius: 20px;
}

.scroll-blue::-webkit-scrollbar-thumb {
	background-color: #479bd2;
	width: 6px;
	border-radius: 20px;
}

.link {
	text-decoration: none;
	color: inherit;
}

/* dashboard guildeline scrollbar */
.dashboard-scroll::-webkit-scrollbar {
	width: 8px;
	height: 5px;
}

.dashboard-scroll::-webkit-scrollbar-track {
	background: #eee;
}

.dashboard-scroll::-webkit-scrollbar-thumb {
	background-color: #479bd2;
	width: 6px;
	border-radius: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
	width: 8px;
	height: 5px;
}

::-webkit-scrollbar-track {
	background: #eee;
	/* color of the tracking area */
}

::-webkit-scrollbar-thumb {
	background-color: #aaa;
	/* color of the scroll thumb */
	width: 6px;
	border-radius: 20px;
	/* roundness of the scroll thumb */
	/* border: 3px solid #aaa;  creates padding around scroll thumb */
}


.workflow-name,
.workflow-sub-heading {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.01em;
}

.workflow-name {
	color: #334d6e;
	font-size: 20px;
	/* line-height: 30px; */
}

.workflow-sub-heading {
	color: #90a0b7;
	font-size: 16px;
	line-height: 19px;
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	display: none;
	min-width: 50px;
	background-color: #787878;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 99;
	top: 125%;
	left: 50%;
	margin-left: -50px;
	font-size: 11px;
	padding: 0.2rem 0.5rem;
	line-height: normal;

	&.datagrid {
		min-height: initial;
		top: 80%;
		margin-left: -90px;
	}
}

.tooltip:hover .tooltiptext {
	display: block;
}

@media screen and (max-width: 768px) {
	::-webkit-scrollbar {
		width: 4px;
	}

	.tooltip:hover .tooltiptext {
		display: none;
	}
}