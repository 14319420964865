.accordion-wrapper {
    background-color: white;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

    &+* {
        margin-top: 1.5rem;
    }

    &.normal {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;

        &+* {
            margin-top: 1rem;
        }

        .accordion-content {
            padding: 0.5rem 0;
        }

        .accordion-header-title {
            font-size: 16px;
            color: #BBBBBB;
        }
    }

    &.chats {
        margin: 0 0 40px 0;
        background-color: #eee;
        padding: 10px;
        border-radius: 0;
        box-shadow: none;

        .accordion-header-title {
            font-size: 16px;
            color: #000;
        }

        .accordion-content {
            padding-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.analytics {
        &-right {
            border-radius: 0;
            padding: 1rem;
            border-left: 10px solid #81DB57;
        }

        &-wrong {
            border-radius: 0;
            padding: 1rem;
            border-left: 10px solid #E44E4E;
        }

        &-open {
            background-color: #F8F8F8;
        }
    }
}

.accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-main {
        display: flex;
        align-items: center;
    }

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid currentColor;
    }

    &:hover,
    &.open {
        color: black;
    }

    &.open {
        &::after {
            content: "";
            border-top: 0;
            border-bottom: 5px solid;
        }

        .accordion-header-title {
            color: #404040;
        }
    }

}

.accordion-image {
    display: block;
    width: 28px;
    height: 28px;
    padding: 0.5rem;
    background-color: #F5F5F5;
    margin-right: 1.5rem;
    border-radius: 50%;
}

.accordion-header {
    &-title {
        font-weight: 600;
        font-size: 18px;
        color: #777;
    }

    &-subtitle {
        font-size: 14px;
        font-weight: 500;
        color: #777;
        margin-top: 0.5rem;
    }
}

.accordion-bullet-points {
    margin-top: 0;
    padding-inline-start: 20px;
}

.accordion-content {
    color: #777;
    padding: 1.5rem 0 0.5rem;
}