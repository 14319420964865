.leverSuccessPage {
    min-height: 100vh;
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px 20px;
    background: linear-gradient(180deg, #f6fafa 50%, #fff 0%);
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            display: block;
            width: 250px;
            height: auto;
        }
        
        h1 {
            margin-top: 10px;
            font-size: 1.75rem;
        }
    }

    .wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &-main {
            transform: translateY(-40%);
            border-radius: 0.5rem;
            background-color: #fff;
            border-bottom: 4px solid red;
            max-width: 500;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

            &.success {
                border-bottom: 4px solid #088397;
            }
        }

        &-content {
            padding: 20px;
            border-bottom: 1px solid #ccc;
        }

        &-content1 {
            padding: 20px;

            select {
                display: block;
                width: 100%;
                font-size: 16px;
                margin-top: 20px;
                padding: 0.5rem 0.75rem;
            }
        }

        &-content2 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .edit-btn {
                font-size: 18px;
                font-weight: bold;
                display: inline-flex;
                align-items: center;
                border: none;
                background-color: transparent;
                border-radius: 0.5rem;

                &:hover {
                    background-color: #eee;
                }

                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin-right: 4px;
                }
            }

            .save-btn {
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                display: inline-flex;
                align-items: center;
                border: none;
                background-color: transparent;
                border-radius: 0.5rem;
                background-color: #088397;

                &:hover {
                    background-color: #087182;
                }

                img {
                    padding: 5px;
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
            }
        }

        &-heading {
            color: red;
            font-weight: 600;
            text-align: center;
            font-size: 20px;
            margin-bottom: 10px;

            &.success {
                color: #088397;
            }
        }

        &-subheading {
            font-size: 18px;
            text-align: center;
        }
    }
}