.container {
    flex: 1;
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-size: 20px;
    font-weight : 600;
}

.subHeading {
    font-size: 16px;
    font-weight: 600;
    color: #8b8b8b;
}

.saveButton {
    padding: 5px 15px;
    background: #479bd2;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #fff;
}

.tableContainer {
    background: #fff;
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;
    flex: 1;
    overflow-y: auto;
}

.tableHeaderContainer {
    display: flex;
    border-bottom: 1px solid #c4c4c4;
}

.tableHeader,
.tableContent {
    flex: 1;
    padding: 10px 20px;
}

.tableContentContainer {
    display: flex;
    border-bottom: 1px solid #c4c4c4;
}

.flex2 {
    flex: 2;
}

.flex4 {
    flex: 4;
}
