.billingPage {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	padding-right: 20%;
	overflow-y: auto;

	.purchase-history {
		border-bottom: 1px solid #000;
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;

		&__title {
			font-weight: 600;
			font-size: 18px;
			color: #334D6E;
			margin-bottom: 1rem;
		}

		&__box {
			display: flex;
			align-items: flex-start;

			&-single {
				width: 25%;

				a {
					text-decoration: none;
				}
			}

			&-title {
				font-weight: 500;
				margin-bottom: 1rem;
			}

			&-text {
				font-weight: 400;
				font-size: 14px;
				color: #818181;
			}

			&-btn {
				background: #479BD2;
				border-radius: 20px;
				padding: 0.5rem 1rem;
				color: white;
				font-weight: 600;
				font-size: 14px;
				cursor: pointer;
				text-align: center;
			}
		}
	}

	.credit-meter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #000;
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;

		&__animation {
			display: flex;
			flex-direction: column;
			align-items: center;

			$baseFontSize: 16;

			@function rem($val) {
				@return #{calc($val / $baseFontSize)}rem;
			}

			.mask {
				position: relative;
				overflow: hidden;

				display: block;
				width: rem(200);
				height: rem(100);
				margin: rem(20);
			}

			.needle {
				position: absolute;
				bottom: 25px;
				left: calc(50% - 6px);
				z-index: 3;
				transform-origin: bottom;
				animation: needle-animation 1s ease-in-out forwards;

				@keyframes needle-animation {
					from {
						transform: rotate(-90deg);
					}

					to {
						transform: rotate(var(--rotationNeedleDegree));
					}
				}
			}

			.needle-box-1 {
				height: 0;
				width: 0;
				border-right: 6px solid transparent;
				border-left: 6px solid transparent;
				border-bottom: 62px solid #000;
			}

			.needle-box-2 {
				width: 12px;
				height: 12px;
				background: rgb(0, 0, 0);
				position: relative;
				border-radius: 12px;
				position: absolute;
				top: 57px;
			}

			.needle-box-3 {
				width: 8px;
				height: 8px;
				background: rgb(204, 204, 204);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 8px;
			}

			.needle-box-4 {
				width: 4px;
				height: 4px;
				background: rgb(0, 0, 0);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 8px;
			}

			.semi-circle {
				position: relative;

				display: block;
				width: rem(200);
				height: rem(100);
				border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;

				&::before {
					content: "";

					position: absolute;
					bottom: 0;
					left: 50%;
					z-index: 2;

					display: block;
					width: rem(140);
					height: rem(70);
					margin-left: rem(-70);

					background: #fff;

					border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;
				}
			}

			.semi-circle--mask {
				position: absolute;
				top: 0;
				left: 0;

				width: rem(200);
				height: rem(200);

				background: transparent;

				animation: semi-circle-mask 1s ease-in-out forwards;
				-webkit-animation: semi-circle-mask 1s ease-in-out forwards;
				transform: translate3d(0, 0, 0);
				transform-origin: center center;
				backface-visibility: hidden;

				@keyframes semi-circle-mask {
					0% {
						transform: rotate(0deg);
					}

					100% {
						transform: rotate(var(--rotationCircleDegree));
					}
				}

				&::before {
					content: "";

					position: absolute;
					top: 0;
					left: 0%;
					z-index: 2;

					display: block;
					width: rem(202);
					height: rem(102);
					margin: -1px 0 0 -1px;

					background: #f2f2f2;

					border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;
				}
			}

			.gauge--1 {
				position: relative;

				.semi-circle {
					background: #088397;
					box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
				}
			}
		}

		&__box {
			display: flex;
			align-items: center;
		}

		&__title {
			font-weight: 500;
			font-size: 18px;
			color: #334D6E;
			margin-bottom: 1rem;
		}

		&__info-box {
			position: relative;
			margin-left: 0.5rem;
		}

		&__info {
			border-radius: 50%;
			border: 2.5px solid #B3B3B3;
			color: #B3B3B3;
			width: 16px;
			height: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: default;
			margin-top: 0.1rem;

			&-modal {
				z-index: 1;
				background-color: white;
				border-radius: 0.5rem;
				left: 100%;
				bottom: 100%;
				width: max-content;
				padding: 0.5rem;
				position: absolute;
				font-weight: 500;
				font-size: 12px;
				color: #818181;
				box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
			}
		}

		&__credit-box {
			display: flex;
			margin-left: 5rem;

			&-title {
				font-weight: 500;
				font-size: 16px;
			}

			&-text {
				font-weight: 400;
				font-size: 15px;
				color: #818181;
			}
		}

		&__add-credits {
			background: linear-gradient(92.1deg, #088397 -2.91%, #FFE600 101%);
			border-radius: 20px;
			padding: 0.5rem 1rem;
			color: white;
			font-weight: 600;
			font-size: 14px;
			cursor: pointer;
			margin-bottom: 1rem;
			text-align: center;
		}

		&__upgrade-now {
			background: linear-gradient(92.1deg, #E44E4E -2.91%, #FFE600 101%);
			border-radius: 20px;
			padding: 0.5rem 1rem;
			color: white;
			font-weight: 600;
			font-size: 14px;
			cursor: pointer;
			text-align: center;
		}
	}

	.cancel-subscription {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__title {
			font-weight: 500;
			font-size: 18px;
			color: #334D6E;
			margin-bottom: 1rem;
		}

		&__text {
			font-weight: 400;
			font-size: 14px;
			color: #818181;
		}

		&__btn {
			background: #999999;
			border-radius: 20px;
			padding: 0.5rem 1rem;
			color: white;
			font-weight: 600;
			font-size: 14px;
			cursor: pointer;
			text-align: center;
			box-sizing: border-box;
		}
	}
}

.creditAlertModal {
	width: 500px;
	max-width: 100%;

	&__title {
		font-weight: 600;
		font-size: 24px;
		text-align: center;
		color: #858585;
	}

	&__text {
		font-weight: 400;
		font-size: 18px;
		text-align: center;
		color: #858585;
		margin: 1rem 0;

		a {
			color: #479BD2;
			text-decoration: none;
		}
	}

	&__credit-btn {
		background: linear-gradient(92.1deg, #088397 -2.91%, #FFE600 101%);
		border-radius: 20px;
		padding: 0.5rem 2rem;
		color: white;
		font-weight: 600;
		font-size: 14px;
		cursor: pointer;
		text-align: center;
		width: fit-content;
		margin: 0 auto;
	}
}

.upgradePlanModal {
	max-width: 100%;

	&__title {
		font-weight: 700;
		font-size: 24px;
		margin-bottom: 2rem;
		text-align: center;
	}

	&__box {
		display: flex;
		align-items: stretch;

		.plan-item {
			margin-right: 40px;
			box-shadow: 0px 4px 4px 0px #00000040;
			border-radius: 25px;
			display: flex;
			flex-direction: column;
			position: relative;

			&:hover {
				cursor: pointer;
			}

			&.active {
				box-shadow: 0px 0px 20px #479BD2;
			}
		}

		.plan-item:last-child {
			margin-right: 0;
		}

		.plan-item .plan-top {
			background-color: #014954;
			border-radius: 25px 25px 0 0;
			padding: 20px 30px;
		}

		.plan-item .plan-popular {
			font-size: 14px;
			color: #313131;
			font-weight: 700;
			padding: 5px 0;
			width: 150px;
			text-align: center;
			border-radius: 10px;
			background-color: #FFD770;
			position: absolute;
			top: 2rem;
			right: 1rem;
		}

		.plan-item .plan-popular:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 15px solid #FFD770;
			position: absolute;
			left: 10px;
			top: 100%;
		}

		.plan-price {
			font-size: 26px;
			color: #fff;
			font-weight: 600;
		}

		.plan-name {
			margin-top: 10px;
			color: #fff;
			font-weight: 600;
			font-size: 18px;
		}

		.plan-description {
			margin-top: 10px;
			font-size: 12px;
			color: #fff;
			font-weight: 400;
		}

		.plan-item .plan-bottom {
			padding: 15px;
			background: #fff;
			border-radius: 0 0 25px 25px;
			flex: 1;
		}

		.plan-item .plan-bottom .roi-input {
			margin-bottom: 25px;
			font-size: 16px;
			color: #043840;
		}

		.plan-item .plan-bottom .roi-input label {
			font-size: 14px;
			margin-bottom: 7px;
			display: block;
		}

		.plan-item .plan-bottom .roi-input input {
			width: 100%;
			border-radius: 6px;
			border: 1px solid #c4c4c4;
			font-family: inherit;
			padding: 5px 15px;
		}

		.plan-feature-item {
			margin-bottom: 10px;
			font-size: 12px;
			line-height: 1.5;
			color: #043840;
			font-weight: 500;
			display: flex;
			flex-direction: row;
		}

		.plan-feature-img {
			width: 20px;
			height: 20px;
			background-image: url(https://storage.googleapis.com/allhqclientpics/website_videos/header-footer.png);
			background-repeat: no-repeat;
			background-size: 90px;
			background-position: -5px -33px;
			margin-right: 10px;
		}

		.plan-feature-text {
			flex: 1;
			font-weight: 500;
			font-size: 14px;
		}

		.plan-feature-text ul {
			padding-left: 20px;
		}
	}

	&__btnBox {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 2rem;
	}

	&__salesBtn {
		font-weight: 600;
		font-size: 20px;
		color: #014954;
		text-decoration: underline;
		margin-right: 2rem;
		cursor: pointer;
	}

	&__proceedBtn {
		background: #479BD2;
		box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
		border-radius: 1rem;
		color: white;
		padding: 0.5rem 2rem;
		font-weight: 600;
		font-size: 20px;
		cursor: pointer;
	}
}

.addCreditModal {
	padding: 1rem 2.5rem;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #D9D9D9;
		padding-bottom: 1rem;

		&-title {
			font-weight: 700;
			font-size: 24px;
		}

		&-total {
			font-weight: 700;
			font-size: 24px;
			padding: 0 1rem 0 8rem;

			span {
				color: #479BD2;
			}
		}
	}

	&__credits {
		padding: 1rem 0;
		border-bottom: 1px solid #D9D9D9;

		&-title {
			font-size: 18px;
			color: #6B6B6B;
			margin-bottom: 0.5rem;
		}

		&-box {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			svg {
				width: 20px;
				height: 20px;
				cursor: pointer;
			}
		}

		&-item {
			padding: 0.5rem 1rem;
			background-color: #F1F1F1;
			border-radius: 0.5rem;
			color: #A9A9A9;
			font-weight: 600;
			font-size: 18px;
			cursor: pointer;

			&:nth-child(2n) {
				margin: 0 1rem;
			}

			&.active {
				background-color: #479BD2;
				color: white;
			}
		}

		&-addBtn {
			background: #479BD2;
			box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
			border-radius: 2rem;
			color: white;
			padding: 0.5rem 2rem;
			font-weight: 600;
			cursor: pointer;
		}

		&-input {
			background-color: #F1F1F1;
			box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 0.5rem;
			padding: 0.5rem 2rem;
			color: #A9A9A9;
			border: none;
			outline: none;
			width: fit-content;
			margin-left: 1rem;
			margin-right: 2rem;
			font-size: 16px;
		}
	}

	&__features {
		padding: 1rem 0;

		&-title {
			font-weight: 500;
			font-size: 20px;
			color: #6B6B6B;
			margin-bottom: 0.5rem;
		}
	}

	&__requestBtn {
		background: #479BD2;
		box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
		border-radius: 2rem;
		color: white;
		padding: 0.5rem 2rem;
		font-weight: 600;
		margin: 0 auto;
		width: fit-content;
		margin-top: 1rem;
		cursor: pointer;
	}
}

.cancelSubscriptionModal {
	border-radius: 30px;
	padding: 1.2rem 2.5rem;
	max-width: 450px;

	&__heading {
		font-size: 18px;
		color: #6B6B6B;
		margin: 1rem 0;
	}

	&__action-btns-box {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;

		.yes-btn {
			background-color: #C4C4C4;
			margin-right: 1rem;
			padding: 0.5rem 2rem;
			border-radius: 25px;
			cursor: pointer;
		}

		.no-btn {
			background-color: #479BD2;
			padding: 0.5rem 2rem;
			border-radius: 25px;
			cursor: pointer;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 28px;
		color: #6B6B6B;

		&.miss-you {
			text-align: center;
		}
	}

	&__subtitle {
		font-weight: 400;
		font-size: 18px;
		color: #6B6B6B;
		margin: 1rem 0 2rem 0;

		&.miss-you {
			text-align: center;
		}
	}

	&__submit-btn {
		margin: 0.5rem auto;
		background-color: #479BD2;
		box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
		border-radius: 25px;
		padding: 0.5rem 1rem;
		color: #fff;
		width: fit-content;
		cursor: pointer;
	}

	&__cancel-btn {
		margin: 0 auto;
		text-decoration-line: underline;
		color: #7E7E7E;
		width: fit-content;
		cursor: pointer;
		font-size: 14px;
	}

	&__textarea-reason {
		resize: none;
		border: none;
		margin-left: 1.8rem;
		padding: 1rem;
		border-radius: 8px;
		background-color: #F7F7F7;
		box-sizing: border-box;
		width: -webkit-fill-available;
	}
}

@media screen and (max-width: 768px) {
	.billingPage {
		padding-right: 0;

		.purchase-history {
			&__box {
				flex-direction: column;

				&-single {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 1rem;
				}

				&-title {
					margin-bottom: 0;
				}
			}
		}

		.credit-meter {
			flex-direction: column;

			&__title {
				margin-bottom: 0;
			}

			&__box {
				flex-direction: column;
			}

			&__credit-box {
				width: 100%;
				margin-left: 0;
				justify-content: space-between;
				margin: 0.5rem 0 1rem 0;

				&-title {
					margin-right: 0.5rem;
				}
			}

			&__credits-used {
				display: flex;
				align-items: center;
			}

			&__buttons-box {
				display: flex;
				align-items: center;
			}

			&__add-credits {
				margin-right: 1rem;
				margin-bottom: 0;
			}

			&__info {
				&-modal {
					left: unset;
					right: 50%;
				}
			}
		}

		.cancel-subscription {
			flex-direction: column;

			&__title {
				margin-bottom: 0.5rem;
			}

			&__btn {
				width: 100%;
				margin-top: 1rem;
			}
		}
	}

	.addCreditModal {
		padding: 1.2rem;

		&__header {
			&-title {
				font-size: 20px;
			}

			&-total {
				font-size: 20px;
				padding: 0 1.5rem 0 2rem;
			}
		}

		&__credits {
			&-item {
				margin-bottom: 1rem;

				&:nth-child(2n) {
					margin: 0 1rem 1rem 1rem;
				}

				&.custom-credits {
					margin-bottom: 0;
				}
			}

			&-input {
				margin-right: 1rem;
				width: 30%;
			}
		}
	}

	.cancelSubscriptionModal {
		padding: 1.2rem;
	}

	.upgradePlanModal {
		&__box {
			flex-direction: column;

			.plan-item {
				margin-right: 0;

				&:nth-of-type(2) {
					margin: 1rem 0;
				}
			}
		}

		&__title {
			margin-bottom: 1rem;
		}

		&__salesBtn {
			font-size: 16px;
			margin-right: 1rem;
		}
		&__proceedBtn {
			font-size: 16px;
			padding: 0.5rem 1rem;
		}
	}
}