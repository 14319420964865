.bottomBarContainer {
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 20px;
	background: #f8f8f8;
	z-index: 1;
}

.bottomBarListItem {
	width: 100%;
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
	justify-content: space-between;
}

.bottomBarListItemText {
	font-weight: 700;
	font-size: 22px;
}

.bottomBarNameInput,
.bottomBarDescInput {
	width: 100%;
	border-radius: 17px;
	padding: 1rem 2rem;
	margin-bottom: 1rem;
	font-size: 13px;
	font-weight: 400;
	background-color: #f2f2f2;
	outline: none;
	border: none;
	box-sizing: border-box;
}

.bottomBarSelectContainer {
	width: 100%;
}

.bottomBarSelectText {
	font-weight: 700;
	font-size: 15px;
	color: #7c7c7c;
	margin-bottom: 0.5rem;
}

.bottomBarSelectBot {
	width: 100%;
	border-radius: 17px;
	padding: 1rem;
	margin-bottom: 1rem;
	font-size: 13px;
	font-weight: 400;
	background-color: #f2f2f2;
	outline: none;
	border: none;
	box-sizing: border-box;
}

.bottomBarNameInput:focus {
	outline: none;
}

.homeIconContainer,
.addIconContainer,
.notificationIconContainer,
.helpIconContainer,
.settingsIconContainer,
.bottomBarListItemIcon {
	width: 20px;
	height: 20px;
}

.bottomBarActive path {
	fill: #479bd2;
}

.quickViewBottomBar {
	padding: 1rem;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.quickViewBottomBar a {
	text-decoration: none;
}

.helpListContainer {
	width: -webkit-fill-available;
	height: 100%;
	max-height: calc(100vh - 112px) !important;
	top: 60px !important;
	left: 0 !important;
	border-radius: unset !important;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
	padding: 0 16px !important;
	display: flex;
	flex-direction: column;
}

.helpListContainer > ul {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.helpSectionOne {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #eaeaea;
	padding: 0.5rem 0;
	text-decoration: none;
	color: inherit;
}

.helpSectionTwo {
	margin-top: auto;
	margin-bottom: 0.5rem;
}

.helpSectionOne .leftBox {
	display: flex;
	align-items: center;
}

.knowledgeIcon,
.rightArrow {
	width: 32px;
	height: auto;
}

.helpSectionOne .text {
	margin-left: 11px;
	font-weight: 500;
	font-size: 15px;
	color: #334d6e;
}

.helpSupportText {
	font-weight: 500;
	font-size: 15px;
}

.helpSupportText a {
	text-decoration: none;
	color: #479bd2;
}

#help-menu {
	z-index: 5;
}

.notificationIconBtn {
	padding: 0;
}
