.bulk-modal {
    &-title {
        font-size: 24px;
        font-weight: 700;
        margin-right: 1.5rem;
    }

    &-subtitle {
        color: #676767;
        margin: 0.5rem 0 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    &-checkbox {
        display: flex;
        margin: 0 12px;
    }

    &-deadline-label {
        margin-right: 16px;
    }

    &-modify {
        background-color: #479bd2;
        padding: 4px 16px;
        font-weight: 700;
        color: '#fff';
        border-radius: 1rem;
        cursor: pointer;
        border: 0;
        font-size: 16px;
    }

    &-cancel {
        background-color: '#f0f0f0';
        padding: 4px 16px;
        font-weight: 700;
        margin-right: 1rem;
        border-radius: 1rem;
        cursor: 'pointer';
        border: 0;
        font-size: 16px;
    }

    &-input-container {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    &-container {
        display: flex;
        flex-direction: column;
        padding-top: 16px
    }
}