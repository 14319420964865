.bpadAddApplicant {
    background: #479BD2;
    font-weight: bold;
    font-family: inherit;
    letter-spacing: 0.01em;
    border-radius: 25px;
    padding: 8px 20px;
    color: #fff;
    white-space: nowrap;
}

.bpadTableGridWrapper {
    display: flex;
    align-items: center;
}

.bpadTableGrid {
    display: grid;
    grid-template-columns: 0.5fr 2fr 2fr;
    gap: 1rem;
    margin: 1rem 0;
    align-items: center;
    width: 88%;
}

.firstColumn {
    text-align: center;
}

.bpadInputs {
    padding: 0.7rem 1rem;
    border-radius: 0.5rem;
    background-color: #edf2f7;
    border: 1px solid #00000000;
}

.bpadTitleBold {
    font-weight: 600;
} 

.bpadForm {
    padding: 1rem 0;
    width: 45vw;
}

.bpadFormBtnsBox {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bpadFormActionBtns {
    display: flex;
    align-items: center;

    .add-question-btn {
        width: 22px;
        height: 22px;
        margin-left: 16px;
    
        &::before {
            height: 2.5px;
            width: 16px;
        }
    
        &::after {
            height: 2.5px;
            width: 16px;
        }
    }

    .delete-question-btn {
        display: block;
        width: 26px;
        height: 26px;
        margin: 0;
        margin-left: 12px;
    }
}