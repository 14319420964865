.user-settings {
	padding: 0 20px;

	.wrapper {
		background: #fff;
		border-radius: 33px;
		box-shadow: 7px 9px 9px rgba(0, 0, 0, 0.25);
		margin: 0;
		padding: 10px 45px;

		.settings-header {
			border-bottom: 1px solid #ececec;
			display: flex;
			flex-direction: row;
			padding: 20px 0;

			>div {
				flex: 1;
				font-size: 18px;
				font-weight: 600;
				padding-left: 30px;
				margin-right: 30px;

				&:first-child {
					margin-left: 90px;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.settings-item {
			padding: 30px 0;
			font-size: 14px;
			display: flex;
			flex-direction: row;
			border-bottom: 1px solid #ececec;
			align-items: center;

			.settings-name {
				font-weight: bold;
				color: #333;
				margin-left: 90px;
				margin-right: 30px;
				flex: 1;
				display: flex;
				flex-direction: row;
				align-items: center;

				>div:first-child {
					margin-right: 20px;
				}
			}

			.settings-description {
				font-weight: bold;
				color: #a4a4a4;
				margin-right: 40px;
				flex: 1;
			}

			.settings-action {
				flex: 1;

				>div:first-child {
					color: #fff;
					background-color: #479bd2;
					border-radius: 20px;
					font-weight: bold;
					padding: 5px 20px;
					display: inline-flex;
					width: 100px;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}

	&__newlogo {
		max-width: 100%;
		max-height: 150px;
		margin-bottom: 15px;
	}

	&__logobox {
		text-align: center;
	}

	&__logoinputbox {
		position: relative;

		&-text {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			width: 100%;
			box-sizing: border-box;
			color: #a4a4a4;
			font-weight: bold;
			border: 2px solid #a4a4a4;
			border-radius: 10px;
			padding: 10px;
			z-index: 2;
		}

		&-input {
			position: absolute;
			height: 52px;
			margin: 0;
			outline: none;
			border-radius: 10px;
			z-index: 5;
			top: 0;
			left: 0;
			opacity: 0;
			right: 0;
			cursor: pointer;
		}

		&-label {
			font-size: 14px;
			color: #a4a4a4;
			margin: 15px;
		}
	}

	&__logomodal {
		width: 400px;
		overflow-y: unset;
		border-radius: 30px !important;
		padding: 20px 40px !important;
	}
}

.container-div {
	width: 100%;
	flex: 1;
	/* height: 100vh; */
	padding: 20px;
	box-sizing: border-box;
	/* overflow-x: scroll; */
}

.top-div {
	width: 76vw;
	margin: 60px auto;
	/* border: 1px solid red; */
}

.inner-top-div {
	display: flex;
	justify-content: space-between;
}

/* table{
	 border: none;
 } */

#btn1 {
	margin-right: 20px;
}

.inner-div-details {
	font-family: Poppins;
}

.inner-div-details p {
	color: #90a0b7;
	font-size: 15px;
}

.inner-div-details h2 {
	color: #334d6e;
	font-size: 18px;
}

.chart-outer-div {
	width: 100% !important;
	box-sizing: border-box;
	margin: 0 auto;
	display: flex;
	background-color: white;
	height: 250px;
	padding: 25px;
	border-radius: 25px;
}

.all-applicant-div {
	border-radius: 25px;
	width: 100% !important;
	box-sizing: border-box;
	background-color: white;
	height: auto;
	padding: 25px;
	margin: 50px auto 0px;
}

.all-applicants-top-div {
	margin-bottom: 15px;
}

.chart-inner-div,
.all-applicant-title {
	display: flex;
}

.circle {
	height: 20px;
	width: 20px;
	outline: none;
	border: none;
	background-color: #f5f5f5;
}

.circle img {
	height: 14px;
	width: 14px;
	margin-left: 3px;
	margin-top: 3px;
}

.title {
	font-family: Mulish;
	margin-left: 15px;
	margin-top: -2px;
}

#chart1 {
	width: 300px;
	margin-top: 50px;
	margin-left: 100px;
}

/* table, tr, td{
	 font-size: 20px;
 }
 
 td{
	 padding: 10px 15px;
 
 } */

.chart-right-div {
	margin: 30px auto;
}

.all-applicants-top-div {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.table-header,
.table-body-row {
	display: flex;
}

.quick-menu-handler {
	/* width: 76vw; */
	flex: 4 1;
	margin: 0 auto;
}

.quick-menu-handler.settings {
	padding: 20px;
	overflow-y: auto;
}

@media screen and (max-width: 768px) {
	.quick-menu-handler.settings {
		padding: 0;
		overflow-y: auto;
	}

	.user-settings {
		padding-bottom: 20px;

		&__mobile {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background: #fff;
			border-radius: 33px;
			box-shadow: 7px 9px 9px rgb(0 0 0 / 25%);
			margin: 0;
			padding: 10px 1rem;
			margin: 0 20px 1rem 20px;
		}

		&__row {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__btn {
			display: block;
			box-sizing: border-box;
			color: #fff;
			border-radius: 8px;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			padding: 0.3rem 0.3rem 0.4rem 0.3rem;
			background-color: rgb(25, 123, 189);
			box-shadow: rgb(16 156 241 / 24%) 0px 4px 10px 0px;
		}

		&__row>p {
			width: 60%;
		}

		&__row.extra-padding {
			padding: 0.5rem 0;
		}

		&__description {
			font-size: 14px;
			color: #a4a4a4;
			padding-top: 0;
			padding-bottom: 0.5rem;
			border-bottom: 1px solid #eaeaea;
		}

		&__description.extra-padding {
			padding-top: 0.5rem;
		}

		&__description:last-of-type {
			border-bottom: 0;
		}
	}
}