.proctoringModal {
    width: 450px;

    .modal-text {
        padding: 0;
        font-size: 24px;
    }

    .proctoring-accordian {
        padding: 10px 20px;
        border-radius: 20px;
        box-shadow: 0px 2px 3px 1px #B0B0B061;
        margin-top: 10px;

        .proctoring-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .proctoring-count {
                .proctoring-type {
                    color: #EA5858;
                    font-size: 18px;
                }

                .proctoring-details {
                    color: #bababa;
                    font-size: 14px;
                    margin-top: 5px
                }
            }

            .proctoring-expand-btn {
                border-bottom: 3px solid #ccc;
                height: 14px;
                width: 14px;
                border-right: 3px solid #ccc;
                transform: rotate(45deg);
                margin-top: -5px;
                cursor: pointer;

                &.active {
                    transform: rotate(-135deg);
                    margin-top: 10px;
                }
            }
        }

        .proctoring-box-expanded {
            .proctoring-timestamps {
                margin-top: 10px;
                margin-bottom: 20px;
                display: flex;

                .proctoring-timestamp-title {
                    font-size: 16px;
                    color: #616161;
                }

                .proctoring-timestamp-details {
                    padding-left: 10px;
                    font-size: 16px;
                    color: #616161;
                }
            }

            .proctoring-resolve-btn {
                display: flex;
                justify-content: flex-end;

                button {
                    margin: 0;
                }
            }
        }
    }
}