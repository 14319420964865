.sidebar {
	width: 20vw;
	min-height: 100vh;
	height: 100%;
	background-color: #fff;
	overflow-y: scroll;
	box-shadow: 2px 0 8px 5px rgba(0, 0, 0, 0.1);
}

.user-div {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
}

.user-img {
	width: 3vw;
	height: 3vw;
	background-image: url(../assets/user.jpg);
	background-position: center;
	background-size: cover;
	border-radius: 1.5vw;
}

.user-details {
	margin-top: 5px;
}

.MuiButton-label {
	font-size: 1.5vh;
}

.customModal-change-pwd .MuiButton-label {
	font-size: inherit;
}

.logo-div {
	padding: 10px;
	border-bottom: 1px solid #ccc;
}

.sidebarWrapper {
	padding: 20px;
	display: flex;
	flex-direction: column;
	color: #555;
}

.sidebarMenu {
	display: block;
	margin-bottom: 10px;
}

.sidebarMenu button {
	padding: 6px 8px;
	border-radius: 4px;
}

.sidebarMenu button:hover {
	box-shadow: unset;
	background-color: rgba(0, 0, 0, 0.04);
}

.sidebarMenu button span {
	font-size: 14px;
	font-weight: 600;
}

.activesidebarMenu button span {
	color: #109cf1;
}

.sidebarTitle {
	font-size: 0.8vw;
	color: #109cf1;
}

.sidebarList {
	list-style: none;
	padding: 5px;
	font-size: 0.8vw;
}

.sidebarListItem {
	padding: 5px;
	cursor: pointer;
	border-radius: 10px;
	color: #109cf1;
	margin: 5px;
	margin-left: 30px;
	font-weight: 600;
}

.sidebarIcon {
	margin-right: 5px;
	font-size: 20px !important;
}

.MuiButton-root {
	text-transform: capitalize !important;
}

.tools {
	border-top: 1px solid #ccc;
	position: absolute;
	bottom: 0px;
	width: 100%;
}

.activeSidebarListItem {
	padding: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	border-radius: 10px;
	color: #555;
	margin: 3px 5px;
	margin-left: 30px;
	font-weight: 600;
	color: #109cf1 !important;
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.link {
	color: #109cf1;
}

#chart {
	margin: 10vh 0px;
	width: 150px;
	height: 150px;
	position: relative;
	top: 100px;
	left: -90px;
}

.logo {
	height: 3.5vh;
	width: 11vw;
}

@media screen and (max-width: 768px) {
	.container {
		display: block;
	}

	.sidebarMenu {
		margin-bottom: 0;
	}

	.MuiButton-label {
		font-size: 14px;
		font-weight: 600;
	}

	.productList {
		padding-top: 0;
	}
}
