.walkthroughWelcome {
    padding: 50px 50px 10px;
    width: 800px;
    box-sizing: border-box;

    .back-btn {
		margin-right: 32px;
		cursor: pointer;
		padding: 10px 0;

		>div {
			position: relative;
			width: 40px;
			height: 2px;
			background-color: #000;

			&:before,
			&:after {
				content: "";
				display: block;
				height: 2px;
				width: 12px;
				left: -2px;
				position: absolute;
				background-color: #000;
			}

			&:before {
				transform: rotate(45deg);
				top: 4px;
			}

			&:after {
				transform: rotate(-45deg);
				top: -4px;
			}
		}
	}

    .extra-features {
        margin-top: 30px;

        .extra-features-item {
            width: 100%;
            border-radius: 10px;
            padding: 10px 25px;
            background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%);
            box-shadow: 0px 0px 10px 0px #00000040;
            cursor: pointer;
            margin-top: 15px;
            font-size: 18px;
            color: #686970;
            font-weight: 500;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;

            &.done {
                border: 2px solid #81DB57;

				> div {
					display: flex;
				}

				img {
					flex-shrink: 0;
				}
            }
        }
    }
}