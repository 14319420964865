.accountmenu {
	padding: 20px;
	color: #334d6e;
	font-family: "Poppins";

	.accountmenu-title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-family: inherit;
			font-size: 18px;
			font-weight: 700;
		}

		h4 {
			color: #898989;
			font-size: 15px;
			font-weight: 400;
		}
	}

	.accountmenu-ul {
		list-style: none;
		padding-left: 0;
		margin-top: 25px;

		li, .external-link {
			display: flex;
			align-items: center;
			margin: 10px 0;
			cursor: pointer;
			text-decoration: none;
			color: inherit;

			p {
				margin-left: 10px;
				font-weight: 700;
				font-size: 16px;
				color: inherit;

				a {
					color: inherit;
					text-decoration: none;
				}
			}

			img {
				width: 18px;
				height: 18px;
			}
		}
	}

	.accountmenu-footer {
		margin-top: 20px;
		color: #898989;

		p {
			font-size: 12px;
		}
	}
}

@media screen and (max-width: 768px) {
	.menu-list-container {
		min-width: 100vw !important;
		min-height: calc(100vh - 112px) !important;
		top: 60px !important;
		left: 0 !important;
		border-radius: unset !important;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
	}

	.menuList {
		padding: 0 !important;
	}

	.settingsIconSvg path {
		fill: #479bd2 !important;
	}

	.logout-modal {
		display: none;
		position: fixed;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgb(0, 0, 0);
		background-color: rgba(0, 0, 0, 0.4);

		&__content {
			background-color: #fff;
			margin: auto;
			padding: 20px;
			width: 80%;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 1rem;

			&-heading {
				font-weight: 700;
				font-size: 15px;
				color: #757575;
				margin-bottom: 1rem;
				text-align: center;
			}

			&-btn {
				background-color: #197bbd;
				border-radius: 8px;
				text-align: center;
				color: #fff;
				border: none;
				outline: none;
				padding: 0.5rem 1rem;
				width: 100%;
			}
		}
	}
}
