.interview-box {
    padding: 30px 20px;
    box-sizing: border-box;
    margin: 10px 25px 25px 20px;
    box-shadow: rgb(0 0 0 / 20%) 5px 5px 10px 5px;
    border-radius: 25px;
    min-height: 320px;

    .title {
        padding: 0 20px;
        color: #4A4A4A;
    }

    .content-box {
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .img-box {
        padding: 5px;
    }

    .note-box {
        color: #8F8F92;
        font-weight: 500;
        font-size: 12px;
    }
}

.second-carousel-box {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .content-box {
        width: 47%;
        margin: 20px 0;
        box-sizing: border-box;
    }

    .thin-border {
        width: 2px;
        background: #E4E4E4;
        margin: 20px 0;
    }
}

.main-slide-box {
    padding: 30px 20px;
    box-sizing: border-box;
    margin: 10px 25px 25px 20px; 
    box-shadow: rgb(0 0 0 / 20%) 5px 5px 10px 5px;
    border-radius: 25px;
    min-height: 320px;
}

@media screen and (max-width: 768px) {
    .interview-box {
        margin: 10px 20px;

        .title {
            padding: 0;
            text-align: center;
            margin-bottom: 10px;
        }

        .content-box {
            flex-direction: column-reverse;
            padding: 0;
        }

        .img-box {
            padding: 0;
        }

        .note-box a {
            word-break: break-all;
        }
    }

    .second-carousel-box {
        flex-direction: column;
        padding: 0;

        .content-box {
            width: 100%;
            margin: 0;

            .heading {
                text-align: center;

                span {
                    margin-left: 0 !important;
                }
            }
        }
        
        .thin-border {
            width: 0;
            background-color: white;
            margin: 5px 0;
        }
    }

    .main-slide-box {
        margin-bottom: 10px;
    }
}