.emailTemplateBox {
    position: fixed;
    top: 60px;
    bottom: 52px;
    height: calc(100% - 60px - 52px);
    background-color: white;
    width: 100%;
    overflow-y: auto;

    &__header {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #e0e0e0;
    }
}

.addMailHeading {
    font-size: 20px;
    font-weight: bold;
    color: #5C5C5C;
    margin-left: 8px;
}

.shortlistMailBox, .rejectionMailBox, .prescreeningMailBox {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shortlistMailBox p, .rejectionMailBox p, .prescreeningMailBox p {
    font-weight: bold;
    font-size: 18px;
    color: #5C5C5C;
}

.singleMailTemplate {
    padding: 10px 20px;
    color: #898989;
}

.addNewMailIcon {
    width: 20px;
    height: 20px;
    background-color: #197BBD;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.addNewMailIcon::before, .addNewMailIcon::after {
    content: "";
    height: 2px;
    width: 14px;
    background-color: #fff;
    display: block;
    border-radius: 3px;
    position: absolute;
}

.addNewMailIcon::before {
    transform: rotate(90deg);
}

.optionsEmailNavbar {
    display: flex;
    padding: 10px 20px;
    background-color: #197BBD;
    justify-content: flex-end;
}

.singleMailSelected {
    background-color: #E0F2FF;
}