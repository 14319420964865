.walkthrough-overlay {
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	align-items: flex-end;

	.step-box {
		position: absolute;
		border-radius: 20px;
		padding: 15px 30px;
		color: #fff;
		font-size: 16px;
		font-weight: 600;
		background: linear-gradient(180deg, #215e80 0%, #077991 100%), linear-gradient(0deg, #479bd2, #479bd2);

		.top-arrow {
			right: 40px;
			content: "";
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 25px solid #215f81;
			position: absolute;
			bottom: 100%;
		}

		.left-arrow {
			right: 100%;
			content: "";
			width: 0;
			height: 0;
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;
			border-right: 25px solid #215f81;
			position: absolute;
			top: 20px;
		}

		.bottom-arrow {
			right: calc(50% - 15px);
			content: "";
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 25px solid #215f81;
			position: absolute;
			top: 100%;
		}

		.right-arrow {
			right: -25px;
			content: "";
			width: 0;
			height: 0;
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;
			border-left: 25px solid #215f81;
			position: absolute;
			top: 20px;
		}
	}
}
