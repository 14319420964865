.snackbar {
    visibility: hidden;
    width: 100%;
    max-width: fit-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 0.5rem 1rem;
    position: fixed;
    z-index: 9999;
    left: 20px;
    bottom: 30px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .snackbar-close-icon {
        color: white;
        font-size: 20px;
        margin-left: 3rem;
        cursor: pointer;
    }
}

.snackbar.snackbar-show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
}

.snackbar.snackbar-show.snackbar-success {
    background-color: #4CAF50;
}

.snackbar.snackbar-show.snackbar-error {
    background-color: #f44336;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadein1 {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 60px;
        opacity: 1;
    }
}

@keyframes fadein1 {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 60px;
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .snackbar {
        left: 50%;
        transform: translate(-50%);
        font-size: 14px;
        min-width: max-content;
        bottom: 60px;
    }

    .snackbar.snackbar-show {
        visibility: visible;
        -webkit-animation: fadein1 0.5s;
        animation: fadein1 0.5s;
    }
}