.teamMainContainer {
	padding: 20px;
	box-sizing: border-box;
	height: 100vh;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.teamContainer {
	width: 100%;
}

.heading-team {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
}

.report-tabs-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.5rem;
}

.report-tabs {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e4e4e4;
	border-radius: 25px;
}

.active-report-tab {
	background-color: #479bd2;
	color: #fff;
	font-weight: 700;
}

.report-tabs .tabContainer {
	padding: 5px;
}

.report-tabs .singleTab {
	padding: 5px 10px;
	border-radius: 15px;
	cursor: pointer;
}

.heading-team div {
	margin: 0px;
}

.heading-team .select {
	margin: 0 0 0 20px;
	width: 200px;
}

input:focus {
	border: none;
	outline: none;
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 18px;
  } */

.header-team {
	display: flex;
	margin: 15px 0 0;
	background: #ccc;
	border-radius: 25px 25px 0px 0px;
}

.header-column-team {
	width: 11vw;
	/* text-align: center; */
	padding: 15px 0 15px 1vw;
	font-weight: 600;
}

/* .row-team {
    display: flex;
    background: #fff;
    border: 1px solid #ddd;
    align-items: center;
  } */

.row-team {
	margin: 0 0 40px 0;
	background-color: #eee;
	padding: 10px;
}

.row-team:last-child {
	margin-bottom: 0;
}

.row-column-team {
	width: 11vw;
	padding: 15px 0 15px 1vw;
	word-break: normal;
}

.header-column-team1 {
	width: 14vw;
	/* text-align: center; */
	padding: 15px 0 15px 1vw;
	font-weight: 600;
}

.row-column-team1 {
	width: 14vw;
	padding: 15px 0 15px 1vw;
	word-break: break-all;
}

.content-team {
	padding: 0 15px;

	&__container {
		display: flex;
		height: 35px;
		padding-top: 3px;
		font-size: 16px;
		flex-direction: column;
	}

	&__input {
		width: 100%;
		box-sizing: border-box;
		background: #f2f2f2;
		border-radius: 17px;
		border: none;
		outline: none;
		padding: 1rem 1.5rem;
		margin-bottom: 1rem;
	}

	&__label {
		color: #7c7c7c;
		margin-bottom: 0.5rem;
	}

	&__select-box {
		width: 100%;
	}

	&__role-select {
		width: 100%;
		height: 35px;
		margin-bottom: 1rem;
		border: 1px solid gray;
		border-radius: 1rem;
	}

	&__loading-gif {
		position: absolute;
		left: 90%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 25px;
		height: 25px;
	}

	&__invite-btn {
		position: relative;
		background-color: #197bbd;
		font-weight: bold !important;
		font-size: 18px !important;
		font-family: "Mulish";
		border-radius: 7px;
		padding: 0.5rem 2rem;
		-webkit-text-fill-color: white;

		&:disabled {
			opacity: 0.5;
			color: white;
			background-color: #197bbd;
		}
	}
}

@media screen and (max-width: 768px) {
	.teamContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: 100vh;
	}

	.heading-team {
		margin-top: 1rem;
	}

	.team-box {
		display: flex;
		flex-direction: column;
		padding: 0.5rem 20px 52px 20px;
		flex: 1;
		background-color: white;
		overflow-y: auto;

		&__heading {
			font-weight: 700;
			font-size: 16px;
			color: #adadad;
		}

		&__row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 1rem 0;
		}

		&__row-left {
			display: flex;
			align-items: center;
		}

		&__row-left-text-name {
			font-weight: 600;
			font-size: 15px;
			color: #404040;
		}

		&__row-left-text-role {
			font-weight: 600;
			font-size: 12px;
			color: #858585;
		}

		&__row-right {
			display: flex;
			align-items: center;
		}

		&__invite-btn-box {
			margin-top: auto;
			position: fixed;
			bottom: 52px;
			left: 0;
			width: 100%;
			padding: 8px 0;
			background-color: white;
			border-top: 1px solid white;
			border-bottom: 1px solid white;
			display: flex;
			justify-content: center;
		}

		&__invite-btn {
			background: #197bbd;
			border-radius: 7px;
			outline: none;
			border: none;
			color: white;
			padding: 0.5rem 2rem;
			font-weight: 700;
			font-size: 18px;
			text-align: center;
			width: 90%;
		}

		&__drawer {
			max-height: 50vh !important;
			height: 100% !important;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 1px -3px 4px rgba(0, 0, 0, 0.25);
			border-radius: 40px 40px 1px 1px;
			overflow-y: auto;
		}
	}

	.customModal-change-pwd {
		max-width: 100%;
		box-sizing: border-box;
	}

	.customModal-change-pwd .input-div-workflow input {
		width: 100%;
		margin: 0;
		box-sizing: border-box;
	}

	.customModal-change-pwd #settingsFlow-selector-4 {
		margin: 0.5rem 0 1rem 0;
	}

	.editModalTeam {
		max-width: 80%;
		box-sizing: border-box;
	}

	.editModalTeam .input-div-workflow {
		margin-top: 0;
	}

	.editModalTeam .input-div-workflow input {
		margin: 0;
		width: 100%;
		box-sizing: border-box;
	}

	.editModalTeam .button-div {
		margin-top: 1rem;
	}
}