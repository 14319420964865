.single-drive {
    margin: 0.5rem 0;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 15px;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.14);

    &__header-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    &__name {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #5C5C5C;
    }

    &__options-img {
        width: 18px;
        height: 18px;
    }

    &__main-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__stats {
        font-weight: 400;
        font-size: 28px;
        color: #404040;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    &__invited-box {
        padding: 0 0.5rem 0 0;
        width: 25%;
    }

    &__invited-text {
        font-weight: 400;
        font-size: 12px;
        color: #1C9130;
        text-align: center;
    }

    &__started-box {
        width: 25%;
        padding: 0 0.5rem;
        border-left: 1px solid #A0A0A0;
        border-right: 1px solid #A0A0A0;
    }

    &__started-text {
        font-weight: 400;
        font-size: 12px;
        color: #197BBD;
        text-align: center;
    }

    &__submitted-box {
        width: 25%;
        padding: 0 0.5rem;
        border-right: 1px solid #A0A0A0;
    }

    &__submitted-text {
        font-weight: 400;
        font-size: 12px;
        color: #E44E4E;
        text-align: center;
    }

    &__reviewed-box {
        width: 25%;
        padding: 0 0 0 0.5rem;
    }

    &__reviewed-text {
        font-weight: 400;
        font-size: 12px;
        color: #404040;
        text-align: center;
    }

    &__menuList-name {
        font-family: inherit !important;

        &:active {
            color: #197BBD !important;
        }
    }
}

.modal-box {
    &__button-box {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }

    &__button-yes {
        font-weight: bold;
        color: #197BBD;
        margin-left: 1rem;
    }

    &__rename-input {
        border: none;
        font-size: 16px;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #A0A0A0;
        box-sizing: border-box;
        width: 100%;
    }

    &__rename-input:focus {
        outline: unset;
        border-bottom: 1px solid #A0A0A0;
    }
}

.react-responsive-modal-modal {
    border-radius: unset;
}

.modal-box {
    &__button-box {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }

    &__button-yes {
        font-weight: bold;
        color: #197BBD;
        margin-left: 1rem;
    }

    &__rename-input {
        border: none;
        font-size: 16px;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #A0A0A0;
        box-sizing: border-box;
        width: 100%;
    }

    &__rename-input:focus {
        outline: unset;
        border-bottom: 1px solid #A0A0A0;
    }
}

.react-responsive-modal-modal {
    border-radius: unset;
}

.modal-box {
    &__button-box {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }

    &__button-yes {
        font-weight: bold;
        color: #197BBD;
        margin-left: 1rem;
    }

    &__rename-input {
        border: none;
        font-size: 16px;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #A0A0A0;
        box-sizing: border-box;
        width: 100%;
    }

    &__rename-input:focus {
        outline: unset;
        border-bottom: 1px solid #A0A0A0;
    }
}

.react-responsive-modal-modal {
    border-radius: unset;
}

.modal-box {
    &__button-box {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }

    &__button-yes {
        font-weight: bold;
        color: #197BBD;
        margin-left: 1rem;
    }

    &__rename-input {
        border: none;
        font-size: 16px;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #A0A0A0;
        box-sizing: border-box;
        width: 100%;
    }

    &__rename-input:focus {
        outline: unset;
        border-bottom: 1px solid #A0A0A0;
    }
}

.imgContain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}