.accountContainer {
	width: 100%;
}

#myAccount {
	flex: 1;
	padding: 20px 20px 0 20px;
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	.myAccHeading {
		margin: 25px 20px 0;
		color: #334d6e;
		font-weight: 700;
		font-size: 18px;
	}

	.wrapper {
		flex: 1;
		padding: 35px 40px 50px;
		margin: 20px 20px 0;
		background: #fff;
		border-radius: 40px 40px 0 0;
		display: flex;
		flex-direction: column;
		overflow-y: auto;

		.myaccount-tabs {
			display: flex;
			border-bottom: 1px solid #EBEFF2;
			padding-bottom: 25px;

			.detailsTab {
				padding-left: 20px;
			}

			.changePassTab {
				padding: 0 60px;
			}

			>div {
				color: #dadada;
				font-weight: 600;
				font-size: 18px;
				cursor: pointer;

				&.active {
					color: #479BD2;
					pointer-events: none;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.accountContainer {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		width: 100%;
	}

	#myAccount {
		min-height: unset;
		height: 100%;

		.myAccHeading {
			margin-top: 0;
		}

		.wrapper {
			margin-top: 1rem;
			padding: 1rem;

			.myaccount-tabs {
				padding-bottom: 1rem;
				
				.detailsTab {
					width: 50%;
					padding: 0;
					text-align: center;
				}

				.changePassTab {
					width: 50%;
					padding: 0;
					text-align: center;
				}
			}
		}
	}
}