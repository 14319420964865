.custom-btn {
    font-family: inherit;
    border-radius: 0.5rem;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    min-width: 64px;
    background-color: unset;

    &:hover {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }

    &:disabled {
        opacity: 0.7;
        pointer-events: none;

        &:hover {
            box-shadow: none;
        }
    }

    &.templateButton {
        &:hover {
            box-shadow: none;
        }
    }
}

.btn-small {
    padding: 0.5rem 0.8rem;
}

.btn-medium {
    padding: 1rem 1.3rem;
}

.btn-large {
    padding: 1.5rem 1.8rem;
}

.btn-contained {
    color: #fff;

    &.button-primary {
        background-color: rgb(71, 155, 210);
    }

    &.btn-secondary {
        background-color: #9c27b0;
    }

    &.btn-success {
        background-color: #2e7d32;
    }

    &.btn-error {
        background-color: #d32f2f;
    }

    &.btn-info {
        background-color: #0288d1;
    }

    &.btn-warning {
        background-color: #ed6c02;
    }
}

.btn-outlined {
    &.button-primary {
        color: rgb(71, 155, 210);
        border: 1px solid rgb(71, 155, 210)
    }

    &.btn-secondary {
        color: #9c27b0;
        border: 1px solid #9c27b0;
    }

    &.btn-success {
        color: #2e7d32;
        border: 1px solid #2e7d32
    }

    &.btn-error {
        color: #d32f2f;
        border: 1px solid #d32f2f
    }

    &.btn-info {
        color: #0288d1;
        border: 1px solid #0288d1
    }

    &.btn-warning {
        color: #ed6c02;
        border: 1px solid #ed6c02
    }
}