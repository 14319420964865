.headerContainer {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 1rem 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebarContainer, .optionsContainer {
    width: 20px;
    height: 20px;
}

.hqLogoContainer {
    width: 100%;
    max-width: 150px;
    height: auto;
    margin: auto;
}

.imgContain {
    object-fit: contain;
    width: 100%;
    height: 100%;
}