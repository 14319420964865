.my-details {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	position: relative;
	flex: 1;

	.my-details-actions {
		position: absolute;
		bottom: 0;
		right: 0;

		>div {
			color: #fff;
			width: 200px;
			padding: 5px 0;
			text-align: center;
			border-radius: 20px;
			background-color: #479BD2;
			font-weight: 700;
			cursor: pointer;

			&:last-child {
				margin-right: 25px;
			}
		}
	}

	.input-group {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.label {
			width: 200px;
			margin-right: 40px;
			font-size: 16px;
			color: #334d6e;
		}

		input {
			width: 250px;
			outline: none;
			border: none;
			background: transparent;
			border-bottom: 1px solid #535353;
			font-size: 14px;
			font-family: inherit;
			color: #a4a4a4;
			-webkit-text-fill-color: #a4a4a4;
		}
	}
}

@media screen and (max-width: 768px) {
	.my-details {
		.input-group {
			flex-direction: column;

			.label {
				width: 100%;
				margin-right: 0;
				margin-bottom: 0.5rem;
			}

			.input-box {
				width: 100%;
			}

			input {
				width: 100%;
			}
		}
		.my-details-actions {
			position: unset;
			bottom: unset;
			right: unset;
			display: flex;
			justify-content: center;

			>div:last-child {
				margin-right: 0;
			}
		}
	}
}