body {
	background-color: #f4f7f8;
}

.productList {
	flex: 4;
	padding: 20px;
}

.productListItem {
	display: flex;
	align-items: center;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
	width: 50vw;
	border-radius: 25px 25px 25px 25px;
}

.add-new-button {
	background-color: #0c95ba;
	color: white;
	border-radius: 25px;
	padding: 5px 15px;
	text-transform: capitalize;
}

.MuiDataGrid-columnHeaderWrapper {
	border-radius: 25px 25px 0px 0px;
}

.MuiDataGrid-root {
	border: none;
}

.productListImg {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

.productView {
	border: none;
	border-radius: 10px;
	padding: 5px 10px;
	background-color: #0c95ba;
	color: white;
	cursor: pointer;
	margin-left: 20px;
}

.productListDelete {
	color: red;
	cursor: pointer;
}

.first-cell-left-align {
	text-align: left;
}

.inner-div {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.productAddButton {
	margin-bottom: 1vh;
	padding: 10px;
	border-radius: 20px;
	border: none;
	background-color: #489bd4;
	color: #fff;
	cursor: pointer;
}

.productTitleContainer {
	display: flex;
	flex-direction: row-reverse;
}

.caseName {
	font-weight: 800;
}

.field {
	display: flex;
	margin: 10px 20px 20px 20px;
}

.field-icon {
	color: white;
	background: darkblue;
	padding: 10px;
	font-size: 30px !important;
	border-radius: 100%;
}

.field-num {
	font-size: 2.3vh;
	font-weight: 600;
}

.field-name {
	font-size: 1.5vh;
	font-weight: 500;
}

.customModal-workflow {
	width: max-content;
	overflow-y: unset;
	border-radius: 34px !important;
	padding: 20px 40px;
}

.modal-heading-workflow {
	font-size: 24px;
	font-weight: 700;
	margin-right: 1.5rem;
}

.input-div-workflow {
	margin-top: 20px;

	input {
		margin: 10px;
		padding: 10px 15px;
		max-width: 350px;
		border-radius: 25px;
		border: 1px solid #aaa;
		font-size: 17.5px;
		box-sizing: border-box;
	}
}

.button-div-workflow {
	display: flex;
	flex-direction: row-reverse;
}

.top-icon-box {
	/* width: 150px;
   overflow: hidden; */
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 20px;
}

.top-icon-box button {
	padding: 4px;
}

.MuiCheckbox-root {
	color: rgb(180, 180, 180) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
	color: rgb(71, 155, 210) !important;
}

.emptyRowImg {
	height: 350px;
	width: auto;
}

.workflowsListContainer {
	height: max-content;
	padding: 10px;
	background: #fff;
	border-radius: 25px;
	overflow: hidden;
	min-height: 500px;
	display: flex;
	flex: 1;
	position: relative;
}

.copyDriveModal {
	padding: 20px 40px;
	width: 720px;

	.title {
		font-size: 24px;
		color: #363434;
		font-weight: 700;
		margin: 0 0 16px 0;
	}

	.action {
		cursor: pointer;
		font-size: 20px;
		color: #fff;
		padding: 12px 30px;
		border-radius: 15px;
		background-color: #479bd2;
		font-family: inherit;
		line-height: 1.2;
		margin-top: 80px;
	}

	input[type="text"],
	textarea {
		font-family: inherit;
		font-size: 16px;
		padding: 16px 50px;
		background-color: #f2f2f2;
		border-radius: 16px;
		border: none;
		outline: none;
		color: #000;
		margin-top: 32px;
		width: 75%;
		resize: none;

		&::placeholder {
			color: #90a0b7;
		}
	}
}

@media screen and (max-width: 768px) {
	.productList {
		background-color: white !important;
		height: 100vh !important;
		padding: 0 !important;
		position: relative !important;
		max-width: 100vw !important;
		overflow-y: hidden;
	}

	.userListTitleContainer {
		padding-top: 0 !important;
	}

	.search-box {
		width: 100% !important;
	}

	.search-box input {
		border-radius: 4px !important;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
	}

	#testNewDriveFlow-selector-1,
	.inner-div {
		display: none;
	}

	.emptyRowImg {
		width: 100%;
	}

	.workflowsListContainer {
		min-height: unset;
		height: 100%;
		overflow-y: auto;
		padding: 10px 20px;
		flex-direction: column;
	}

	.modal-heading-workflow {
		font-size: 20px;
	}

	.customModal-workflow {
		width: 100%;
		box-sizing: border-box;
		margin-left: 0;
		margin-right: 0;
		max-width: min-content;
		padding: 20px;
	}

	.customModal-workflow .button-div {
		margin-top: 20px;
	}
}