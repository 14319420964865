//************************** MAINBAR STYLING ***********************//

.mainbar {
	flex: 4 1;
	padding: 20px;
	box-sizing: border-box;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	scrollbar-width: thin;
	scrollbar-color: #90a4ae #cfd8dc;

	&::-webkit-scrollbar {
		width: 11px;
	}

	&::-webkit-scrollbar-track {
		background: #cfd8dc;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #90a4ae;
		border-radius: 6px;
		border: 3px solid #cfd8dc;
	}
}

//************************** NOTIFICATION STYLING ***********************//

.notificationbar {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	&__icon-box {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 20px;

		button {
			display: inline-block;
			padding: 8px;
		}
	}
}

//************************** ACTIONBAR STYLING ***********************//

.actionbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0;
	padding: 20px 20px 0;

	&__bulk-action-div {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__details {
		font-family: "Poppins";

		h2 {
			color: #334d6e;
			font-size: 18px;
		}

		p {
			color: #90a0b7;
			font-size: 15px;
		}
	}

	&__search-box {
		flex: 1;
		max-width: 55vw;
		margin: 0 15px;

		input {
			width: 100%;
			outline: none;
			border: none;
			padding: 10px 20px 10px 50px;
			border-radius: 17px;
			background-image: url("../assets/Icons/search.png");
			background-position: 20px 9.5px;
			background-repeat: no-repeat;

			&::placeholder {
				padding-left: 0px;
				font-weight: 500;
				font-size: 15px;
				color: rgb(150, 150, 150);
			}
		}
	}

	&__btn-box {
		a {
			text-decoration: none;
		}
	}
}

//************************** BUTTON STYLING ***********************//

button.btn-primary {
	background: #479bd2;
	font-weight: bold;
	letter-spacing: 0.01em;
	border-radius: 25px;
	font-size: 15px;
	padding: 7px 18px;
	text-transform: none;
	text-decoration: none;
	margin-right: 10px;
	color: #fff;
	border: none;

	&:hover {
		background: #479bd2;
	}

	span,
	a {
		color: inherit;
		text-decoration: none;
		font-family: inherit;
		font-weight: inherit;
		font-size: inherit;
		white-space: nowrap;
	}

	a {
		display: flex;
		align-items: center;
	}

	img {
		margin-left: 5px;
		width: 18px;
		height: 18px;
	}
}

button.btn-gradient {
	background: #479bd2;
	background-image: linear-gradient(to left, #23597c, #008095);
	font-weight: bold;
	font-family: Poppins;
	letter-spacing: 0.01em;
	border-radius: 25px;
	font-size: 18px;
	padding: 2px 30px;
	text-transform: none;
	text-decoration: none;
	margin-right: 10px;
	color: #fff;

	&:hover {
		background: linear-gradient(to left, #23597c, #008095);
	}

	span,
	a {
		color: inherit;
		text-decoration: none;
		font-family: inherit;
		font-weight: inherit;
		font-size: inherit;
		white-space: nowrap;
	}
}

button.btn-datagrid {
	background: #479bd2;
	font-weight: bold;
	font-family: "Mulish";
	letter-spacing: 0.01em;
	border-radius: 25px;
	font-size: 14px;
	padding: 5px 10px;
	text-transform: none;
	text-decoration: none;
	margin-right: 10px;
	color: #fff;
	min-width: fit-content;

	&:hover {
		background: #479bd2;
	}

	span,
	a {
		color: inherit;
		text-decoration: none;
		font-family: inherit;
		font-weight: inherit;
		font-size: inherit;
		white-space: nowrap;
	}

	&-long {
		padding: 5px 21px;
	}

	&-invite {
		padding: 5px 28px;
	}
}

//************************** DATA GRID STYLING ***********************//

.datagrid {
	&__container {
		padding: 10px;
		background: #fff;
		border-radius: 25px;
	}

	&__scroll {
		border-radius: 25px !important;
		border: none !important;
		overflow-y: auto;
		padding: 0 10px;
		height: 100% !important;
		background-color: #fff;
		scrollbar-width: thin;
		scrollbar-color: #90a4ae #cfd8dc;

		&::-webkit-scrollbar {
			width: 11px;
		}

		&::-webkit-scrollbar-track {
			background: #cfd8dc;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #90a4ae;
			border-radius: 6px;
			border: 3px solid #cfd8dc;
		}
	}

	&__row {
		text-align: left;
		text-decoration: none;
		width: 100%;
		color: #404040;
		font-size: 15px;
		font-weight: 400;

		&-center {
			text-align: center;
			margin-right: 4ch;
		}
	}

	&__bulkaction {
		background: lightgrey;
		min-height: 6vh;
		padding: 1vh 20px;
		display: flex;
		justify-content: space-between;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;

		&-btnbox {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
}

//************************** DATAGRID EMPTY ROWS STYLING ***********************//
.norowsoverlay {
	&__container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	&__img {
		height: 350px;
		width: auto;
	}

	&__text {
		font-size: 20px;
		font-weight: 700;
		color: #334d6e;
	}
}

//************************** MODAL STYLING ***********************//
.modal {
	&__container {
		width: 620px;
		overflow-y: unset;
		border-radius: 30px !important;
		padding: 20px 40px !important;
	}

	&__heading {
		font-family: "Mulish";
		font-weight: 700;
		color: #000;
		font-size: 24px;
		margin-bottom: 20px;
	}

	&__inputbox {
		input {
			margin: 10px;
			padding: 10px 15px;
			width: 350px;
			border-radius: 25px;
			border: 1px solid #aaa;
			font-size: 17.5px;
			background-color: #f2f2f2;

			&:focus,
			&:active {
				border: 1px solid #479bd2;
			}
		}

		textarea {
			margin: 10px;
			padding: 10px 15px;
			width: 350px;
			height: 130px;
			border-radius: 25px;
			border: 1px solid #aaa;
			font-size: 17.5px;

			&:focus,
			&:active {
				border: 1px solid #479bd2;
				outline: none;
			}
		}
	}

	&__label {
		margin: 0 15px;
		font-size: 18px;
		display: block;

		&-error {
			font-size: 14px;
			color: red;
			margin-left: 24px;
		}
	}

	&__select {
		width: 380px;
		height: 45px;
		margin: 10px;
		background-color: #f2f2f2;
		border-radius: 30px !important;
	}

	&__btnbox {
		display: flex;
		flex-direction: row-reverse;
		padding: 0px;
		margin-top: 20px;
	}
}

.customModal {
	max-width: 90vw;
}

.support-modal-input {
	font-size: 14px;
	padding: 10px 20px;
	outline: none;
	width: 100%;
	background-color: #f2f2f2;
	border-radius: 10px;
	border: none;
	box-sizing: border-box;

	&::placeholder {
		font-size: 14px;
		color: #9E9E9E;
	}
}

//************************** DATA GRID HEADER TEXT STYLING ***********************//

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: 0.01em;
	color: #334d6e;
}

@media screen and (max-width: 768px) {
	.mainbar {
		padding: 0;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.notificationbar {
		display: none;
	}

	.actionbar {
		padding: 0;
		margin-top: 0;
		margin-bottom: 0;

		&__details {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.5rem 20px 0 20px;
		}

		&__btn-box {
			display: flex;
			background: white;
			white-space: nowrap;
			flex-direction: column;
			position: absolute;
			bottom: 0;
			width: 100%;
			box-sizing: border-box;
			left: 0;
			padding: 20px;
			white-space: nowrap;
		}
	}

	button.btn-primary {
		margin-right: 0;
		width: 100%;
		border-radius: 8px;
		background-color: rgb(25, 123, 189);
	}

	#quickViewFlow-selector-8 {
		margin: 0.5rem 0;
	}

	#quickViewFlow-selector-8:disabled {
		opacity: 0.7;
		color: white;
	}
}