.productList {
	flex: 4;
	padding: 20px;
	box-sizing: border-box;
	height: 100vh;
	display: flex;
	flex-direction: column;
	width: calc(80vw - 40px);
}

.productListItem {
	display: flex;
	align-items: center;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
	width: 50vw;
	border-radius: 25px 25px 25px 25px;
}

.add-new-button {
	background-color: #0C95BA;
	color: white;
	border-radius: 25px;
	padding: 5px 15px;
	text-transform: capitalize;

}

.MuiDataGrid-columnHeaderWrapper {
	border-radius: 25px 25px 0px 0px;
}

.MuiDataGrid-root {
	border: none;
}

.productListImg {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

.productView {
	border: none;
	border-radius: 10px;
	padding: 5px 10px;
	background-color: #0C95BA;
	color: white;
	cursor: pointer;
	margin-left: 20px;
}

.productListDelete {
	color: red;
	cursor: pointer;
}

.first-cell-left-align {
	text-align: left;
}

.productAddButton {
	margin-bottom: 1vh;
	padding: 10px;
	border-radius: 20px;
	border: none;
	background-color: #489bd4;
	color: #fff;
	cursor: pointer;
}

.productTitleContainer {
	display: flex;
	flex-direction: row-reverse;
}

.caseName {
	font-weight: 800;
}

.field {
	display: flex;
	margin: 10px 20px 20px 20px;
}

.field-icon {
	color: white;
	background: darkblue;
	padding: 10px;
	font-size: 30px !important;
	border-radius: 100%;
}

.field-num {
	font-size: 2.3vh;
	font-weight: 600;
}

.field-name {
	font-size: 1.5vh;
	font-weight: 500;
}

.input-div-workflow {
	margin-top: 20px;
	/* height: 250px; */
}

.input-div-workflow textarea {
	margin: 10px;
	padding: 10px 15px;
	width: 350px;
	height: 130px;
	border-radius: 25px;
	border: 1px solid #aaa;
	font-size: 17.5px;
}

.button-div-workflow {
	display: flex;
	flex-direction: row-reverse;
}

.userListUser {
  color: inherit;
  text-decoration: none;
}

.userListImg {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

.userListEdit {
	border: none;
	border-radius: 10px;
	padding: 5px 10px;
	background-color: #3bb077;
	color: white;
	cursor: pointer;
	margin-right: 20px;
}

.userListTitleContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5vh;
	align-items: center;
	margin: 10px 0;
	padding: 0 20px;
}

/* .search-box{
  margin-top: 10px;
} */

.userListDelete {
	color: red;
	cursor: pointer;
}

.userListApplicantContainer {
	min-height: 500px;
	height: max-content;
	padding: 10px;
	background: #fff;
	border-radius: 25px;
	overflow: hidden;
}

.userListApplicantContainer {
	min-height: 500px;
	height: max-content;
	padding: 10px;
	background: #fff;
	border-radius: 25px;
	overflow: hidden;
}

@media screen and (max-width: 768px) {
	.userListTitleContainer {
		flex-direction: column;
		align-items: unset;
	}

	.export-btn {
		display: none;
	}

	.inner-div-details {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 0.5rem;
	}

	.userListApplicantContainer {
		padding: 0 20px;
		min-height: fit-content;
		flex: 1;
		overflow-y: auto;
	}

	.allApplicants.productList {
		height: 100vh !important;
	}

	.applicant-box {
		display: flex;
		flex-direction: column;
		padding: 0.5rem 20px;
		flex: 1;
		background-color: white;
	}

	.applicant-box__heading {
		font-weight: 700;
		font-size: 16px;
		color: #ADADAD;
	}

	.applicant-box__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 1rem 0;
	}

	.applicant-box__row-left {
		display: flex;
		align-items: center;
	}

	.applicant-box-row-left-text-box {
		margin-left: 1rem;
	}

	.applicant-box__row-left-text-role {
		font-weight: 600;
		font-size: 12px;
		color: #858585;
	}

	.applicant-box__row-right {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
	}

	.applicant-box__row-left-image-box {
		position: relative;
	}

	.applicant-box__row-left-image-box img {
		width: 16px;
		height: 16px;
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.editModalApplicants {
		margin: 0;
		box-sizing: border-box;
	}

	.editModalApplicants .input-div-workflow input {
		margin: 0;
		width: 100%;
		box-sizing: border-box;
	}

	.editModalApplicants .button-div {
		margin-top: 0.5rem;
	}

	.applicant-box__row-left-text-name {
		font-weight: 600;
		font-size: 15px;
		color: #404040;
	}

	.applicant-box__invite-btn {
		background: #197BBD;
		border-radius: 7px;
		outline: none;
		border: none;
		color: white;
		padding: 0.5rem 2rem;
		font-weight: 700;
		font-size: 18px;
		text-align: center;
		margin-top: auto;
	}

	.applicant-box__drawer {
		max-height: 50vh !important;
		height: 100% !important;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 1px -3px 4px rgba(0, 0, 0, 0.25);
		border-radius: 40px 40px 1px 1px;
		overflow-y: auto;
	}
}
