.paper {
    width: 60vh;
    margin: 1rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 30px !important;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
}

.signinWrapper {
    display: flex;
    height: 100vh;
    width: 100%;
}

.singinBg,
.signinForm {
    width: 50%;
}

.signinContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signinInput {
    border: none;
    outline: none;
    background-color: rgb(223, 223, 223);
    padding: 15px 12px;
    border-radius: 25px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
}

.signinRightText h1 {
    margin: 10px 0;
    color: #fff;
    font-weight: 800;
    font-size: 38px;
}

.signinRightText h2 {
    background-color: #fff;
    color: rgb(0, 128, 149);
    padding: 8px 15px;
    box-sizing: border-box;
}

.demoinputContainer label,
.signininputContainer label {
    font-weight: 600 !important;
    font-family: "Poppins";
}

.demoinput label,
.signinInput label {
    color: rgba(0, 0, 0, 0.54) !important;
}

.sideImage {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -100;
}

.signinBtn {
    font-weight: bold;
    font-family: "Poppins";
    letter-spacing: 0.01em;
    border-radius: 15px;
    padding: 5px 50px;
    font-size: 16px;
    background-image: linear-gradient(to right, #23597c, #008095);
}

.logoContainer {
    margin-bottom: 50px;
}

.logoImage {
    display: inline-block;
    width: 150px;
    margin: 20px 0 10px;
}

.inputLabel {
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}

.inputLabel2 {
    color: #008095;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    margin-bottom: 1rem;
}

.inputLabel3 {
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}

.inputLabelForgot {
    color: #008095;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    margin-bottom: 1rem;
    cursor: pointer;
    text-decoration: none;
    display: block;
}

.backBtn {
    font-weight: 600;
    margin-top: 0.5rem;
    color: #008095;
    cursor: pointer;
    width: fit-content;
    text-decoration: none;
    display: block;
}

.resetPasswordBtn {
    font-weight: bold;
    font-family: "Poppins";
    letter-spacing: 0.01em;
    border-radius: 15px;
    padding: 5px 50px;
    font-size: 16px;
    background-image: linear-gradient(to right, #23597c, #008095);
    margin-top: 3rem;
}

@media (max-width: 600px) {
    .signinWrapper {
        position: relative;
        flex-direction: column-reverse;
        background-image: url("../assets/signinbg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .singinBg {
        background-image: none !important;
    }

    .logoContainer {
        position: absolute;
        top: 5%;
        left: 25%;
        margin-bottom: 0;
    }

    .logoImage {
        width: 200px;
    }

    .signinRightText {
        padding: 3rem 0;
        text-align: center;
    }

    .inputLabel {
        display: none;
    }

    .inputLabel2 {
        color: #fff;
    }

    .inputLabel3 {
        color: #fff;
    }

    .inputLabelForgot {
        color: #fff;
    }

    .resetPasswordBtn {
        background-image: none;
        background-color: #fff !important;
        color: #008095;
        width: 100%;
    }

    .backBtn {
        margin: 0 auto;
        margin-top: 0.5rem;
        color: #fff;
    }

    .signinRightText h1 {
        font-size: 25px;
    }

    .signinInput {
        background-color: transparent;
        border-bottom: white solid 1px;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        color: white !important;
    }

    .signinInput:focus {
        border-bottom: white solid 1px !important;
        color: white !important;
    }

    .signinInput::placeholder {
        color: white;
    }

    .sideImage {
        display: none;
    }

    .singinBg,
    .signinForm {
        width: 100%;
    }

    .signinBtn {
        background-color: white !important;
        background-image: none !important;
        color: #0a768f !important;
        width: 100% !important;
    }

    .paper {
        width: 85vw;
    }

    .clientDashboard,
    .efficientHiring {
        display: none;
    }
}
