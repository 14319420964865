.wrapperCC {
	background-color: #f9f9f9;
	width: 400px;
	padding: 10px;
	border-radius: 15px;
	margin-right: 40px;
	display: flex;
	align-items: flex-start;
	min-height: 45px;

	&-title {
		font-weight: 700;
		font-size: 16px;
		color: #5a5a5a;
		margin-right: 10px;
	}

	&-inputEmails {
		width: 100%;
	}
}

.wrapperBCC {
	background-color: #f9f9f9;
	width: 400px;
	padding: 10px;
	border-radius: 15px;
	margin-right: 40px;
	margin-top: 10px;
	display: flex;
	align-items: flex-start;
	min-height: 45px;

	&-title {
		font-weight: 700;
		font-size: 16px;
		color: #5a5a5a;
		margin-right: 10px;
	}

	&-inputEmails {
		width: 100%;
	}
}

.inputCC {
	width: 100%;
	height: 45px;
	margin-top: 1rem;
	box-sizing: border-box;
	font: inherit;
	border: 0px;
	color: #565656;
	background-color: rgba(255, 255, 255, 0);
	-webkit-appearance: none;
	font-size: 14px;
}

.inputCC:focus {
	border-color: #eaeaea;
	outline: none;
}

.inputCC.has-error {
	border-color: tomato;
}

.errorCC {
	margin: 0;
	font-size: 90%;
	color: tomato;
}

.tag-itemCC {
	background-color: #cfecff;
	display: inline-block;
	font-size: 14px;
	border-radius: 30px;
	height: 30px;
	padding: 0 4px 0 1rem;
	display: inline-flex;
	align-items: center;
	margin: 0 0.3rem 0.3rem 0;
	color: #5a5a5a;
}

.tag-itemCC > .button {
	background: transparent;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	font: inherit;
	margin-left: 10px;
	font-weight: bold;
	padding: 0;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #8b8b8b;
	font-size: 20px;
}

.save-itemCC {
	background-color: #cfecff;
	display: inline-block;
	font-size: 14px;
	border-radius: 30px;
	height: 30px;
	padding: 0 4px 0 1rem;
	display: inline-flex;
	align-items: center;
	margin: 0 0.3rem 0.3rem 0;
	color: #5a5a5a;
}

.save-itemCC > .button {
	background: transparent;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	font: inherit;
	margin-left: 10px;
	font-weight: bold;
	padding: 0;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #8b8b8b;
	font-size: 20px;
}

.MuiFormControlLabel-root {
	margin-top: 15px;
}

.cc-form-group {
	.modal-checkbox,
	.modal-checkbox a {
		margin-bottom: 15px;
		color: #656565;
		font-size: 16px;
		font-weight: 500;
		position: relative;
	}

	.modal-checkbox label {
		padding-left: 30px;
	}

	.modal-checkbox input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.modal-checkbox .checkmark {
		position: absolute;
		top: 1px;
		left: 0;
		height: 18px;
		width: 18px;
		border: 1px solid #109cf1;
		cursor: pointer;
		border-radius: 4px;
	}

	.modal-checkbox .checkmark:hover input ~ .checkmark {
		background-color: #ccc;
	}

	.modal-checkbox input:checked ~ .checkmark {
		background-color: #109cf1;
	}
	
	.modal-checkbox .checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}
	
	.modal-checkbox input:checked~.checkmark:after {
		display: block;
	}
	.modal-checkbox .checkmark:after {
		left: 6px;
		top: 2px;
		width: 4px;
		height: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.mainContainer {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 768px) {
	.mainContainer {
		flex-direction: column;
	}

	.wrapperCC,
	.wrapperBCC {
		width: 100%;
		box-sizing: border-box;
		margin-bottom: 1rem;
		border-radius: 0.5rem;
		flex-direction: column;
	}
}
