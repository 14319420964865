.container-div {
	/* width: 76vw; */
	width: auto;
	height: 100%;
	/* margin: 0 auto; */
	box-sizing: border-box;
	overflow-y: auto;
}
.top-div {
	width: 76vw;
	margin: 60px auto;
	/* border: 1px solid red; */
}

.inner-top-div {
	display: flex;
	justify-content: space-between;
}
.App {
	font-family: sans-serif;
	text-align: center;
}
/* table{
    border: none;
} */

#btn1 {
	margin-right: 20px;
}

.inner-div-details {
	font-family: Poppins;
}

.inner-div-details p {
	color: #90a0b7;
	font-size: 13px;
}

.inner-div-details h2 {
	color: #334d6e;
	font-size: 20px;
}

.chart-outer-div {
	width: 76vw;
	margin: 0 auto;
	display: flex;
	background-color: white;
	height: 250px;
	padding: 25px;
	border-radius: 25px;
}

.chart-outer-div2 {
	width: auto;
	margin: 0 auto;
	display: flex;
	background-color: white;
	padding: 25px 25px 0 25px;
	border-radius: 25px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.all-applicant-div {
	border-radius: 25px;
	width: 76vw;
	background-color: white;
	height: 300px;
	padding: 25px;
	margin: 20px auto;
}

.all-applicants-top-div {
	margin-bottom: 15px;
}

.chart-inner-div,
.all-applicant-title {
	display: flex;
}

.circle {
	height: 20px;
	width: 20px;
	outline: none;
	border: none;
	background-color: #f5f5f5;
}

.circle img {
	height: 14px;
	width: 14px;
	margin-left: 3px;
	margin-top: 3px;
}

.title {
	font-family: Mulish;
	margin-left: 15px;
	margin-top: -2px;
}

#chart1 {
	width: 300px;
	margin-top: 50px;
	margin-left: 100px;
}

.chart-right-div td {
	padding: 10px 15px;
}

.all-applicants-top-div {
	display: flex;
	justify-content: space-between;
}

.table-header,
.table-body-row {
	display: flex;
}

.quick-menu-handler {
	width: 76vw;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding: 20px;
	height: 100vh;
	overflow: hidden;
	box-sizing: border-box;
}

.analytics-header {
	padding: 20px 20px 0;
}

.quick-menu-handler .chart-outer-div2 .chart-right-div .stats-heading {
	text-align: center;
	font-weight: 600;
	color: #334d6e;
}

.quick-menu-handler .chart-outer-div2 .chart-right-div .stats-text {
	text-align: center;
	color: #888;
}

.quick-menu-handler .chart-outer-div2 .chart-right-div .stats-heading {
	margin-left: 0.5rem;
}

.quick-menu-mobile-handler {
	width: 82vw;
	margin: 0 auto;
}

@media screen and (max-width: 768px) {
	.App {
		width: 100% !important;
		height: auto !important;
	}
	.quick-menu-mobile-handler .quick-menu-handler {
		width: 100vw;
		height: 100%;
	}
	.quick-menu-mobile-handler {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
	}
	.chart-outer-div2 {
		flex-direction: column;
		box-shadow: none;
	}
	.analytics-header {
		padding: 0;
	}
	.quick-menu-handler .container-div {
		padding: 0;
		overflow-y: auto;
	}
	.quick-menu-handler #chart1 {
		margin: 0;
		width: 100%;
	}
	.quick-menu-handler .chart-inner-div {
		margin-bottom: 1rem;
	}
	.quick-menu-handler .chart-outer-div {
		flex-direction: column;
		padding-bottom: 0;
	}
	.quick-menu-handler .chart-outer-div .chart-right-div {
		display: none;
	}
	.quick-menu-handler .chart-outer-div2 .chart-right-div {
		margin: 0;
	}
	.quick-menu-handler .chart-outer-div2 .chart-right-div .stats-heading {
		font-size: 16px;
		text-align: left;
	}
	.quick-menu-handler .chart-outer-div2 .chart-right-div .stats-text {
		font-size: 16px;
	}
}
