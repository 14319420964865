.reactCustomModal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;

    &__overlay {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    &__container {
        height: 100%;
        outline: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
    }

    &__content {
        background-color: #fff;
        margin: 1.2rem;
        padding: 20px;
        max-width: 800px;
        overflow-y: auto;
        position: relative;
        border-radius: 1rem;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    }

    &__center:after {
        width: 0;
        height: 100%;
        content: '';
        display: inline-block;
        vertical-align: middle;
    }

    &__close {
        position: absolute;
        color: #000;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        z-index: 2;

        svg {
            width: 28px;
            height: 28px;
        }
    }

    .button-div {
        display: flex;
        flex-direction: row-reverse;
        padding: 0px;
    }

    .modal-text {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        padding: 0 20px 1rem 1rem;
    }

    .cancel-modal-button {
        color: white;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .reactCustomModal {
        &__content {
            max-width: 100%;
        }

        &__close {

            svg {
                width: 22px;
                height: 22px;
            }
        }

        .modal-text {
            padding: 0 1.5rem 20px 0;
        }
    }
}