.search-box.review-applicants {
	flex: 1;
	margin: 0 5px;
}

.check-btn:hover {
	color: #81db57;
}

.cancel-btn:hover {
	color: red;
}

.visibility-btn:hover,
.article-btn:hover {
	color: #479bd2;
}

.check-btn,
.cancel-btn,
.visibility-btn {
	color: #c4c4c4;
}

.MuiDataGrid-iconSeparator,
.MuiSvgIcon-root {
	display: none;
}

#scroll-down-animation {
	position: absolute;
	top: 380px;
	padding: 8px 20px;
	border-radius: 30px;
	background: #479bd2;
	color: #fff;
	animation: scrollDown 2s infinite;
	z-index: 5;
}

#scroll-down-animation:before {
	content: "";
	display: block;
	background: #479bd2;
	height: 18px;
	width: 18px;
	position: absolute;
	bottom: 0;
	right: 50%;
	z-index: 1;
	transform: translate(50%, 9px) rotateZ(45deg);
}

@keyframes scrollDown {
	0% {
		top: 380px;
	}

	50% {
		top: 400px;
	}

	100% {
		top: 380px;
	}
}

.carousel-container {
	margin: auto;
	padding: 0 10px;
	position: relative;
	/* text-align: center; */
}

.owl-carousel .owl-nav {
	overflow: hidden;
	height: 0px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #5110e9;
}

.owl-carousel .nav-button {
	height: 45px;
	width: 45px;
	cursor: pointer;
	position: absolute;
	top: 150px;
	background-color: rgba(0, 0, 0, 0.7);
}

.owl-carousel .nav-button img {
	height: 45px;
	width: 45px;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
	pointer-events: none;
	opacity: 0.25;
}

.owl-carousel .owl-prev.nav-button {
	left: -40px;
	display: flex;
}

.owl-carousel .owl-next.nav-button {
	right: -35px;
	display: flex;
}

.owl-theme .owl-nav [class*="owl-"] {
	color: #ffffff;
	font-size: 39px;
	background-color: transparent;
	border-radius: 3px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
	color: #ffffff;
	font-size: 39px;
	background-color: transparent;
	border-radius: 3px;
}

.owl-carousel .prev-carousel:hover {
	background-position: 0px -53px;
}

.owl-carousel .next-carousel:hover {
	background-position: -24px -53px;
}

.owl-theme .owl-dots {
	display: none;
}

.customModalChats {
	width: 850px;
	max-width: 850px;
}

.customModalChats .container-team {
	padding: 0 20px 20px;
	margin: 0;
	height: 625px;
}

.customModalChats .scrollblue {
	margin-top: 20px;
	max-height: 450px;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 10px 10px 10px 20px;
}

.customModalChats .interviewers-guide-btn-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}

.customModalChats .performance-overview-box {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.customModalChats .performance-stats {
	flex: 1;
	margin: 0 15px;
}

.customModalChats .report-tab-box {
	padding-right: 10px;
	position: relative;
}

.customModalChats .functional-skills-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.customModalChats .functional-skills-box .single-functional-skill {
	width: 45%;
	box-sizing: border-box;
	padding: 20px;
	margin: 15px 0;
	border-radius: 30px;
	box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
}

.customModalChats .performance-overview-box .doughnut-chart-box {
	height: 240px;
	padding: 20px;
	border: 1px solid rgb(150, 150, 150);
	border-radius: 15px;
}

@media screen and (max-width: 768px) {
	.search-box.review-applicants {
		margin: 0;
	}

	.export-btn.review-applicants {
		display: none;
	}

	.notification-btns-box.review-applicants {
		display: none;
	}

	.review-applicant-box__content {
		padding: 0 20px;
		overflow-y: auto;
		height: 100%;
	}

	.customModalChats {
		position: absolute;
		padding: 20px 10px;
		top: 60px;
		left: 0;
		bottom: 0;
		margin: 0;
		width: 100%;
		box-sizing: border-box;
		overflow-y: unset;
		border-radius: 0;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;

		.container-team {
			padding: 0;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 0 1rem;
		}

		.heading-team {
			padding: 0;
		}

		.report-tabs-container {
			display: none;
		}

		.action-btns-box {
			display: none;
		}

		.scrollblue {
			padding: 0;
			overflow-y: unset;
			overflow-x: unset;
		}

		.interviewers-guide-btn-box {
			display: none;
		}

		.performance-overview-box {
			flex-direction: column;
		}

		.performance-overview-box .doughnut-chart-box {
			height: auto;
		}

		.performance-stats {
			margin: 0;
			margin-top: 1rem;
		}

		.report-tab-box {
			padding-right: 0;
		}

		.functional-skills-box {
			padding: 0 0.5rem;
			flex-direction: column;
		}

		.functional-skills-box .single-functional-skill {
			width: 100%;
			box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
		}
	}

	#scroll-down-animation {
		display: none;
	}
}