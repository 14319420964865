.video-applicant {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;

        .text {
            font-weight: 500;
            font-size: 16px;
            color: #BDBDBD;
        }
    }

    &-wrapper {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 20px;

        &-header {
            display: flex;
            align-items: center;
            padding: 0.5rem 0 1rem 0;
            margin-bottom: 1.8rem;
            border-bottom: 1px solid #ECECEC;

            .left-arrow,
            .right-arrow {
                width: 50px;
                height: 50px;
                background-color: transparent;
                border: none;

                &:disabled {
                    opacity: 0.4;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .candidate {
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;

                &-info {
                    display: flex;
                    align-items: center;

                    &-basic {
                        padding-left: 20px;

                        .name {
                            color: #083A50;
                            font-size: 20px;
                            font-weight: 600;

                        }

                        .email {
                            color: #969696;
                            font-size: 16px;
                            font-weight: normal;
                        }
                    }
                }

                &-data {

                    &-status,
                    &-score {
                        color: #969696;

                        span {
                            color: #014954;
                        }
                    }
                }
            }
        }

        &-body {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &-left {
                width: 48%;
                display: flex;
                flex-direction: column;

                .video {
                    width: 100%;
                    margin-bottom: 1.5rem;
                    border-radius: 16px;
                }

                .no-video-found {
                    width: 100%;
                    height: 300px;
                    background-color: #F9F9F9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1.5rem;
                    border-radius: 16px;
                }

                .note {
                    margin-top: auto;
                    background-color: #F9F9F9;
                    border-radius: 8px;
                    padding: 0.5rem 1rem;
                    font-size: 16px;
                    outline: none;
                    resize: none;
                    border: none;

                    &::placeholder {
                        color: #ADADAD;
                    }
                }

                .score-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 1rem;

                    &-input {
                        width: 48%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .text {
                            font-weight: 600;
                            font-size: 16px;
                            color: #868686;
                            margin-right: 1rem;
                            white-space: nowrap;
                        }

                        input {
                            background-color: #F9F9F9;
                            border-radius: 8px;
                            padding: 0.5rem 1rem;
                            font-size: 16px;
                            outline: none;
                            display: inline-block;
                            border: none;
                            width: inherit;
                        }
                    }

                    &-total {
                        width: 48%;
                        font-weight: 600;
                        font-size: 16px;
                        color: #868686;
                        text-align: right;

                        span {
                            font-size: 18px;
                            color: #81DB57;
                        }
                    }
                }
            }

            &-right {
                width: 48%;
                max-height: 560px;
                overflow-y: auto;
                border-radius: 25px;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                padding: 8px;
                display: flex;
                flex-direction: column;

                .heading {
                    font-weight: 700;
                    font-size: 18px;
                    color: #6CA1C4;
                    margin-bottom: 1rem;
                    padding: 0.5rem 0.5rem 0;
                }

                .no-data {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        display: block;
                        width: 200px;
                        height: auto;
                    }

                    p {
                        font-size: 20px;
                        font-weight: 700;
                        color: #334D6E
                    }
                }

                .video-question-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 12px;
                    cursor: pointer;
                    border-radius: 1rem;
                    box-sizing: border-box;

                    &.active {
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                    }

                    &:hover {
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                    }

                    .question {
                        flex-shrink: 0;
                        font-weight: 600;
                        font-size: 16px;
                        color: #555555;
                        line-clamp: 2;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        word-break: break-word;
                    }

                    .play-icon {
                        margin: 0 1rem;
                        flex-shrink: 0;
                        border-radius: 50%;
                        background-color: #CCD2E3;
                        width: 40px;
                        height: 40px;
                        -webkit-mask: url('../assets/Icons/play-icon.svg') no-repeat center;
                        mask: url('../assets/Icons/play-icon.svg') no-repeat center;

                        &.active {
                            background-color: #479BD2;
                        }
                    }
                }
            }
        }
    }
}