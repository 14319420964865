.toggle-case {
	list-style: none;
	display: flex;
	margin-bottom: 10px;
}

.finalizedContainer {
	overflow-x: scroll;
}

.toggle-case li {
	margin-right: 10px;
	cursor: pointer;
}

.toggle-case .active {
	color: orange;
	text-decoration: underline orange;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer {
	text-align: center;
}

.container-team {
	margin: 25px;
}

.reviewIconBox {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* @media screen and (min-width: 1366px){
    .userList{
      width: 1000px;
    }
  }
  
  @media screen and (min-width: 1280px){
    .userList{
      width: 1000px;
    }
  } */

@media screen and (max-width: 768px) {
	.reviewIconBox {
		display: none;
	}
	.finalizedContainer {
		overflow-x: hidden;
	}
	.finalizedContainer .userList {
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.bottomDrawer__img-box {
		display: flex;
		justify-content: flex-end;
	}

	.bottomDrawer__checkbox-grp {
		margin-bottom: 1rem;
	}

	.bottomDrawer__checkbox-grp input[type="checkbox"] {
		width: 14px;
		height: 14px;
	}

	.bottomDrawer__checkbox-grp label {
		color: #334d6e;
		font-size: 18px;
		margin-left: 0.5rem;
	}
}
