.videobox-header {
	background-color: #fff;
	margin-bottom: 5px;
	border-radius: 25px 25px 0 0;
	padding: 20px;
	margin-top: 20px;
}

.videobox-header p {
	color: #334d6e;
	font-size: 16px;
	font-weight: bold;
}

.videobox-wrapper {
	display: flex;
	flex-wrap: nowrap;
	background-color: #fff;
	border-radius: 0 0 25px 25px;
	overflow-x: auto;
}

.videobox-loader {
	border: 4px solid #f3f3f3;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	border-top: 4px solid #555;
	border-radius: 50%;
	width: 32px;
	height: 32px;
}

.videobox {
	width: 40%;
	padding: 20px 0;
	margin: 0 10px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;

	&-arrow {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: fit-content;
		margin-top: 30px;
		background-color: transparent;
		outline: 0;
		border: 0;

		&-text {
			display: inline-block;
			font-size: 14px;
			text-align: center;
		}
	}

	&-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		// height: 300px;
		gap: 16px;
		// background-color: red;

		.left {
			display: flex;
			flex-direction: column;
			align-items: center;

			.score-label {
				font-family: "Mulish";
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 0px;
				margin-left: 0px;
				margin-right: 0px;
				margin-top: 0px;
				text-align: left;
				color: rgb(180, 180, 180);
			}

			.score {
				.score-input {
					background-color: rgb(230, 230, 230);
					border-bottom-color: rgb(100, 100, 100);
					border-bottom-left-radius: 25px;
					border-bottom-right-radius: 25px;
					border-bottom-style: none;
					border-bottom-width: 0px;
					border-image-outset: 0;
					border-image-repeat: stretch;
					border-image-slice: 100%;
					border-image-source: none;
					border-image-width: 1;
					border-left-color: rgb(100, 100, 100);
					border-left-style: none;
					border-left-width: 0px;
					border-right-color: rgb(100, 100, 100);
					border-right-style: none;
					border-right-width: 0px;
					border-top-color: rgb(100, 100, 100);
					border-top-left-radius: 25px;
					border-top-right-radius: 25px;
					border-top-style: none;
					border-top-width: 0px;
					color: rgb(100, 100, 100);
					display: inline-block;
					font-family: "Mulish";
					font-size: 15px;
					font-weight: 700;
					margin-bottom: 0px;
					margin-left: 0px;
					margin-right: 0px;
					margin-top: 0px;
					outline-color: rgb(100, 100, 100);
					outline-style: none;
					outline-width: 0px;
					padding-bottom: 5px;
					padding-left: 10px;
					padding-right: 10px;
					padding-top: 5px;
					text-align: start;
					width: 50px;
					margin: 0 16px;
				}

				.score-percent {
					color: rgb(180, 180, 180);
					font-weight: bold;
				}
			}

			.remarks {
				background-color: rgb(230, 230, 230);
				border-bottom-color: rgb(100, 100, 100);
				border-bottom-left-radius: 25px;
				border-bottom-right-radius: 25px;
				border-bottom-style: none;
				border-bottom-width: 0px;
				border-image-outset: 0;
				border-image-repeat: stretch;
				border-image-slice: 100%;
				border-image-source: none;
				border-image-width: 1;
				border-left-color: rgb(100, 100, 100);
				border-left-style: none;
				border-left-width: 0px;
				border-right-color: rgb(100, 100, 100);
				border-right-style: none;
				border-right-width: 0px;
				border-top-color: rgb(100, 100, 100);
				border-top-left-radius: 25px;
				border-top-right-radius: 25px;
				border-top-style: none;
				border-top-width: 0px;
				color: rgb(100, 100, 100);
				font-family: "Mulish";
				height: 80px;
				margin-bottom: 0px;
				margin-left: 0px;
				margin-right: 0px;
				margin-top: 0px;
				outline-color: rgb(100, 100, 100);
				outline-style: none;
				outline-width: 0px;
				padding-bottom: 10px;
				padding-left: 15px;
				padding-right: 15px;
				padding-top: 10px;
				text-align: start;
				margin: 16px 0;
				width: 80%;
				resize: none;
			}
		}

		.right {
			padding: 1rem 5px;

			&-subtitle {
				color: #BCBCBC;
				font-size: 16px;
				font-weight: bold;
				margin: 10px 0;
			}

			&-title {
				color: #4A4A4A;
				font-weight: bold;
				font-size: 20px;
			}

			&-input {
				width: 100%;
				border: none;
				outline: none;
				color: #4A4A4A;
			}
		}
	}

	.transcript {
		padding: 20px 0;

		&-grammer {
			padding: 16px;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
			border-radius: 10px;
			width: fit-content;
			height: fit-content;
			display: flex;
			align-items: center;
		}

		&-grammer:nth-child(1) {
			padding: 10px 20px;
		}

		&-grammer:nth-child(2) {
			padding: 10px 16px;
			margin: 0px 10px;
			flex: 2;
		}

		&-remarks {
			font-family: 14px;
			background-color: rgb(230, 230, 230);
			border-bottom-color: rgb(100, 100, 100);
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
			border-bottom-style: none;
			border-bottom-width: 0px;
			border-left-color: rgb(100, 100, 100);
			border-left-style: none;
			border-left-width: 0px;
			border-right-color: rgb(100, 100, 100);
			border-right-style: none;
			border-right-width: 0px;
			border-top-color: rgb(100, 100, 100);
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			border-top-style: none;
			border-top-width: 0px;
			color: rgb(100, 100, 100);
			font-family: "Mulish";
			margin-bottom: 0px;
			margin-left: 0px;
			margin-right: 0px;
			margin-top: 0px;
			outline-color: rgb(100, 100, 100);
			outline-style: none;
			outline-width: 0px;
			padding-bottom: 10px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 10px;
			text-align: start;
			margin: 10px 0;
			resize: none;
			font-size: 14px;
			width: 100%;
		}

		&-label {
			font-family: "Mulish";
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 0px;
			margin-left: 0px;
			margin-right: 0px;
			margin-top: 0px;
			text-align: left;
			color: rgb(180, 180, 180);
		}

		&-input {
			background-color: rgb(230, 230, 230);
			border-bottom-color: rgb(100, 100, 100);
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
			border-bottom-style: none;
			border-bottom-width: 0px;
			border-image-outset: 0;
			border-image-repeat: stretch;
			border-image-slice: 100%;
			border-image-source: none;
			border-image-width: 1;
			border-left-color: rgb(100, 100, 100);
			border-left-style: none;
			border-left-width: 0px;
			border-right-color: rgb(100, 100, 100);
			border-right-style: none;
			border-right-width: 0px;
			border-top-color: rgb(100, 100, 100);
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			border-top-style: none;
			border-top-width: 0px;
			color: rgb(100, 100, 100);
			display: inline-block;
			font-family: "Mulish";
			font-size: 15px;
			font-weight: 700;
			margin-bottom: 0px;
			margin-left: 0px;
			margin-right: 0px;
			margin-top: 0px;
			outline-color: rgb(100, 100, 100);
			outline-style: none;
			outline-width: 0px;
			padding-bottom: 5px;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 5px;
			text-align: center;
			width: 50px;
			margin-left: 16px;
		}


		&-text-elements-container {
			padding: 16px 0;
		}

		&-title {
			font-family: "Mulish";
			font-size: 18px;
			font-weight: 800;
			color: #4A4A4A;
			margin-bottom: 20px;
		}

		&-sub-title {
			font-family: "Mulish";
			color: #6B6B6B;
			font-weight: bold;
			font-size: 16px;
			padding-bottom: 10px;
		}

		&-summary-desc {
			font-family: "Mulish";
			color: #6B6B6B;
			font-size: 14px;
			font-weight: 600;
			text-align: justify;
		}

		&-text-elements {
			display: flex;
			gap: 10px;
		}

		&-text-block-container {
			display: inline-block;
			background-color: #EFEFEF;
			border-radius: 25px;
			padding: 8px 16px;

			&-text {
				font-size: 12px;
				font-family: "Poppins";
				color: #6B6B6B;
			}
		}

		&-score-label {
			// background-color: #81DB57;
			display: inline-block;
			padding: 8px 16px;

			&-text {
				color: #fff;
				font-weight: bold;
			}
		}

	}
}

.bar {
	position: relative;
	height: 10px;
	border-radius: 10px;
	border: 1px solid #F1F1F1;

	&-back {
		background-color: #F1F1F1;
		border-radius: 10px;
		border: 1px solid #F1F1F1;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&-front {
		position: absolute;
		background-color: #81DB57;
		border-radius: 10px;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
	}
}

.score-tile {
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	grid-template-rows: 1fr;
	align-items: center;
	padding: 14px;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
	margin: 10px 0;
	border-radius: 10px;

	&-title {
		color: #504848;
		font-size: 14px;
		font-weight: bold;
		padding-bottom: 4px;
	}

	&-text {
		font-size: 14px;
		text-align: center;
		color: #696969;
		font-weight: 700;
	}

	&-img {
		max-width: 100%;
		height: 40px;
		width: 40px;
	}
}

.questionbox {
	width: 100%;
	margin: auto;
	flex: 1;
	border-radius: 25px;
	background-color: rgba(200, 200, 200, 0.1);
}

.video {
	text-align: center;
}

.video video {
	width: 100%;
	border-radius: 25px;
	margin: 15px 0;
}

.questionbox p {
	color: #334d6e;
	font-size: 14px;
	font-weight: bold;
	padding: 10px 20px;
}

.card-container {
	padding: 20px;
	border-radius: 25px;
	box-shadow: 5px 8px 8px 4px rgba(0, 0, 0, 0.15);
}

.card-container .items {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px;
	margin: 5px 0;
}

.items p:first-child {
	width: 80%;
}

items p:last-child {
	width: 20%;
	text-align: center;
}

.card-container hr {
	border: none;
	outline: none;
	height: 3px;
	border-radius: 15px;
	background-color: rgba(150, 150, 150, 0.3);
}

.card-container p {
	font-weight: bold;
}

.card-container p.score {
	font-size: 18px;
}

.card-container p.percent {
	font-size: 24px;
	color: rgb(180, 180, 180);
}

.card-container p.pitch,
p.content,
p.pitch-number {
	font-size: 16px;
	color: rgb(100, 100, 100);
}

.card-container input.score-input {
	font-size: 16px;
	color: rgb(100, 100, 100);
}

.card-container input.score-input::-webkit-inner-spin-button,
.card-container input.score-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.card-container p.pitch-number {
	padding: 0 10px;
}

.card-container p.content {
	width: 50%;
}

.card-container p.input {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-self: center;
}

.card-container p.input input {
	display: inline-block;
	width: 50px;
	border-radius: 25px;
	font-weight: bold;
	background-color: rgb(230, 230, 230);
	border: none;
	outline: none;
	padding: 5px 10px;
	color: rgb(100, 100, 100);
}

.card-container p.input img {
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-right: 10px;
}

.card-container .items textarea {
	width: 100%;
	height: 80px;
	outline: none;
	border: none;
	border-radius: 25px;
	background-color: rgb(230, 230, 230);
	border: none;
	outline: none;
	padding: 10px 15px;
	color: rgb(100, 100, 100);
	resize: none;
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}