.zohoAuthorizationPage {
    min-height: 100vh;
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px 20px;
    background: linear-gradient(180deg, #f6fafa 50%, #fff 0%);
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            display: block;
            width: 250px;
            height: auto;
        }
        
        h1 {
            margin-top: 10px;
            font-size: 1.75rem;
        }
    }

    .wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &-main {
            max-width: 500px;
            transform: translateY(-40%);
            border-radius: 0.5rem;
            background-color: #fff;
            border-bottom: 4px solid red;
            max-width: 500;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

            &.success {
                border-bottom: 4px solid #088397;
            }
        }

        &-content {
            padding: 20px;
        }

        &-heading {
            color: red;
            font-weight: 600;
            text-align: center;
            font-size: 24px;
            margin-bottom: 10px;

            &.success {
                color: #088397;
            }
        }

        &-subheading {
            font-size: 18px;
            text-align: center;
        }
    }
}